import React, { useCallback } from "react";

import { QueryCode } from "../components/Code";
import { Definition, Dictionary } from "../types/api";
import { Button, Form, Select } from "antd";
import {
  slackNotificationsToStringList,
  slackNotificationsFromStringList,
  userNotificationsFromStringList,
  userNotificationsToStringList,
  replaceNotifications
} from "../lib/notifications";
import TextArea from "antd/lib/input/TextArea";
import { nameSorter } from "./UserSelect";
import { useUsersList } from "../hooks/api";

interface DefinitionSummaryProps {
  definition: Definition;
  errors: Dictionary;
  updateDefinition: (changes: Partial<Definition>) => void;
  onSave: () => void;
  isSaving: boolean;
}

const DefinitionSummary: React.FC<DefinitionSummaryProps> = ({
  definition,
  updateDefinition,
  onSave,
  isSaving
}) => {
  const { users } = useUsersList({ messagable: true });
  const changeSlackNotifications = useCallback(
    (vals: string[]) => {
      const newSlackNotifications = slackNotificationsFromStringList(vals);
      updateDefinition({
        notifications: replaceNotifications(
          "slack",
          definition.notifications,
          newSlackNotifications
        )
      });
    },
    [updateDefinition, definition.notifications]
  );
  const changeUserNotifications = useCallback(
    (vals: string[]) => {
      const newUserNotifications = userNotificationsFromStringList(vals);
      updateDefinition({
        notifications: replaceNotifications("user", definition.notifications, newUserNotifications)
      });
    },
    [updateDefinition, definition.notifications]
  );

  return (
    <>
      {definition.data_getter_type === "bigquery_sql" && (
        <QueryCode>{definition.data_getter_arguments.sql_query}</QueryCode>
      )}
      <Form layout="vertical">
        <Form.Item
          label="Slack Channels"
          help="Alerts will be sent here. Only public channels, must begin with #, you must add @Helios to the channel in Slack! Posting to multiple channels is supported."
        >
          <Select
            mode="tags"
            tokenSeparators={[" "]}
            value={slackNotificationsToStringList(definition.notifications)}
            onChange={changeSlackNotifications}
            open={false}
          ></Select>
        </Form.Item>
        <Form.Item
          label="Direct Messages"
          help="People listed here will get notified via a private Slack message when an alert is triggered from this defintion."
        >
          <Select
            mode="multiple"
            tokenSeparators={[" "]}
            value={userNotificationsToStringList(definition.notifications)}
            onChange={changeUserNotifications}
            filterOption={(input, option) => {
              const name = option.props.children;
              if (typeof name !== "string") return false;
              return name.toLowerCase().startsWith(input.toLocaleLowerCase());
            }}
          >
            {users.sort(nameSorter).map(u => (
              <Select.Option key={u.id} value={u.id.toString()}>
                {u.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Notes"
          help="Add notes about how this defintion works, it's quirks or current status. These are visible to everyone."
        >
          <TextArea
            value={definition.admin_notes || ""}
            onChange={e => updateDefinition({ admin_notes: e.target.value })}
            rows={8}
          />
        </Form.Item>
        <Button onClick={onSave} loading={isSaving} type="primary">
          Save Changes
        </Button>
      </Form>
    </>
  );
};

export default DefinitionSummary;
