import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

const ErrorView: React.FC<{ error: Error }> = ({ error }) => {
  if (error.message === "Not Found")
    return (
      <Result
        status="warning"
        title="404"
        subTitle="Not Found"
        extra={
          <Button type="primary">
            <Link to="/">Back Home</Link>
          </Button>
        }
      />
    );
  return (
    <Result
      status="error"
      title="Bang!"
      subTitle="An error has occured"
      extra={
        <Button type="primary" onClick={() => window.location.reload()}>
          Try Again
        </Button>
      }
    />
  );
};

export default ErrorView;
