import { Notifications, SlackNotification, UserNotification } from "../types/api";

export const slackNotificationsToStringList = (notifications: Notifications | null | undefined) =>
  notifications
    ? notifications
        .filter(n => n.notification_type === "slack")
        .map(n => (n as SlackNotification).slack_channel)
    : [];

export const userNotificationsToStringList = (notifications: Notifications | null | undefined) =>
  notifications
    ? notifications
        .filter(n => n.notification_type === "user")
        .map(n => (n as UserNotification).user_id.toString())
    : [];

export const slackNotificationsFromStringList = (strs: string[]): SlackNotification[] =>
  strs
    .map(slack_channel => slack_channel.trim())
    .map(slack_channel => (slack_channel.startsWith("#") ? slack_channel : "#" + slack_channel))
    .filter(slack_channel => slack_channel.length > 0)
    .map(slack_channel => ({ notification_type: "slack", slack_channel }));

export const userNotificationsFromStringList = (strs: string[]): UserNotification[] =>
  strs
    .map(id => id.trim())
    .filter(id => id.length > 0)
    .map(id => ({ notification_type: "user", user_id: Number(id) }));

export const replaceNotifications = (
  type: "user" | "slack",
  notifications: Notifications,
  newUserNotifications: UserNotification[] | SlackNotification[]
): Notifications => [
  ...notifications.filter(n => n.notification_type !== type),
  ...newUserNotifications
];
