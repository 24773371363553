import React, { useMemo } from "react";
import { JobsAPIParams, Dictionary } from "../types/api";
import LoadingSpinner from "../components/LoadingSpinner";
import TimeseriesChart from "../components/TimeseriesChart";
import { Result } from "antd";
import { metricName, normaliseGrain } from "../lib/definition-utils";
import { useTimeseries, useJob, useJobsList } from "../hooks/api";

interface DefintionChartProps {
  definitionId: number | string;
  filterAnomalousMarksByJobId?: number[];
  showOptions?: boolean;
}

const DefinitionChart: React.FC<DefintionChartProps> = ({
  definitionId,
  filterAnomalousMarksByJobId,
  showOptions = true
}) => {
  const urlParams: JobsAPIParams = {
    page_size: 150,
    order_by: "created_at_desc",
    page: 1
  };
  if (definitionId) urlParams.definition_id = definitionId;
  const { jobs, isLoadingJobsList } = useJobsList(urlParams);
  const firstCompleteJobMatch = jobs.find(j => j.detection_event_at !== null);
  const { job: firstCompleteJob, isLoadingJob } = useJob(
    firstCompleteJobMatch ? firstCompleteJobMatch.id : null
  );

  const { timeseries, isLoadingTimeseries } = useTimeseries(firstCompleteJob);
  const anomalousMarks = useMemo(() => {
    if (timeseries === null) return [];
    const ticks: [string, Date][] = timeseries.data.datetime.map(t => [
      t,
      new Date(t.replace(" ", "T"))
    ]);
    return jobs
      .filter(
        j => filterAnomalousMarksByJobId == null || filterAnomalousMarksByJobId.includes(j.id)
      )
      .reduce((ret, j) => {
        if (j.detection_event_at) {
          const timestamp = j.detection_event_at;
          ret[timestamp] = j.anomalous ? j.direction : "normal";
        } else {
          const match = ticks.find(([t, d]) => d >= new Date(j.created_at));
          if (match) {
            const timestamp = match[0];
            ret[timestamp] = "incomplete";
          }
        }
        return ret;
      }, {} as Dictionary);
  }, [jobs, filterAnomalousMarksByJobId, timeseries]);

  if (isLoadingJobsList || isLoadingJob || isLoadingTimeseries) return <LoadingSpinner />;

  if (firstCompleteJob === null || timeseries === null)
    return (
      <Result
        status="warning"
        title="No Job Data Available"
        subTitle={`None of the last ${jobs.length} jobs for this definition have completed successfully`}
      />
    );

  return (
    <TimeseriesChart
      timeseries={timeseries}
      grain={normaliseGrain(firstCompleteJob.definition)}
      metricName={metricName(firstCompleteJob.definition)}
      anomalousMarks={anomalousMarks}
      endNow
      showOptions={showOptions}
    />
  );
};

export default DefinitionChart;
