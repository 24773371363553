import styled from "styled-components/macro";

// e.g. <Grid columns="1fr 1fr">
const Grid = styled.div<{ columns?: string; gutter?: string }>`
  display: grid;
  grid-template-columns: ${({ columns = "1fr 1fr" }) => columns};
  grid-column-gap: ${({ gutter = "20px" }) => gutter};
`;

export const Span = styled.div<{ columns: number }>`
  grid-column: span ${({ columns }) => columns};
`;

export default Grid;
