import React from "react";

import Page, { Title, Header } from "../components/Page";

const NotFoundPage: React.FC = () => {
  return (
    <Page>
      <Header>
        <Title data-testid="page-title">Not Found</Title>
      </Header>
      <p>This page could not be found :(</p>
    </Page>
  );
};

export default NotFoundPage;
