import React from "react";
import { Alert } from "antd";

const DOMAINS = ["helios.lvh.systems", "helios.lvh.me:3000"];

export const DomainWarning: React.FC = () => {
  if (DOMAINS.includes(window.location.host)) return <></>;
  return (
    <Alert
      banner
      message={
        <>
          Note: <strong>helios.lvh.systems</strong> is the new url please update your bookmarks
        </>
      }
    />
  );
};
