import React from "react";
import styled from "styled-components/macro";

interface DimensionPillProps {
  readonly name: string;
  readonly value: any;
}

const Pill = styled.span`
  display: inline-block;
  background-color: #f4f4f4;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  font-size: 13px;
  overflow: hidden;
  line-height: 1.2;
  margin: 5px 5px 0 0;
  &:last-child {
    margin-right: 0;
  }
`;

const Name = styled.span`
  display: inline-block;
  border-right: 1px solid #dbdbdb;
  padding: 4px 5px;
`;

const Value = styled.span`
  display: inline-block;
  background-color: #fff;
  padding: 4px 5px;
  max-width: 300px;
`;

const ValueWrap = styled.span`
  display: block;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const valueForDisplay = (value: string | boolean): string => {
  if (typeof value === "string") return value;
  return value ? "Yes" : "No";
};

const DimensionPill: React.FC<DimensionPillProps> = ({ name, value }) => (
  <Pill>
    <Name>
      <ValueWrap>{name}</ValueWrap>
    </Name>
    <Value>
      <ValueWrap>{valueForDisplay(value)}</ValueWrap>
    </Value>
  </Pill>
);

export default DimensionPill;
