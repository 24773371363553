import React from "react";
import { Feedbacks, Usefulness } from "../types/api";
import { Table, Tag, Button, Popover } from "antd";
import { FEEDBACK_USEFULNESS } from "../lib/feedback";

interface FeedbackListProps {
  feedbacks: Feedbacks;
  onDelete: (feedbackId: number) => void;
}

const FeedbackList: React.FC<FeedbackListProps> = ({ feedbacks, onDelete }) => {
  return (
    <Table rowKey="id" dataSource={feedbacks} pagination={false} size="middle">
      <Table.Column
        key="usefulness"
        title="Usefulness"
        dataIndex="usefulness"
        render={(usefulness: Usefulness) => (usefulness ? FEEDBACK_USEFULNESS[usefulness] : "")}
      />
      <Table.Column
        key="positive"
        title="Positive"
        dataIndex="positive"
        render={value => {
          if (value == null) return "";
          return value ? <Tag color="green">Yes</Tag> : <Tag>No</Tag>;
        }}
      />
      <Table.Column
        key="negative"
        title="Negative"
        dataIndex="negative"
        render={value => {
          if (value == null) return "";
          return value ? <Tag color="green">Yes</Tag> : <Tag>No</Tag>;
        }}
      />
      <Table.Column key="created_by" title="Email" dataIndex="created_by" />
      <Table.Column
        key="notes"
        title="notes"
        dataIndex="notes"
        render={note =>
          note?.length > 30 ? (
            <Popover title="Notes" content={note} overlayStyle={{ whiteSpace: "pre-line" }}>
              {note.slice(0, 25)}...
            </Popover>
          ) : (
            note
          )
        }
      />
      <Table.Column
        key="delete"
        title="Actions"
        dataIndex=""
        render={(_value, row: any) => (
          <Button type="danger" size="small" onClick={() => onDelete(row.id)}>
            Delete
          </Button>
        )}
      />
    </Table>
  );
};

export default FeedbackList;
