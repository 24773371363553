import React, { useState } from "react";

import Page, { Title, Subtitle } from "../components/Page";
import { FormSection, FormSectionBody, Field, SelectOptions } from "../components/Form";
import TabList, { Tab, TabTitle } from "../components/TabList";
import Grid, { Span } from "../components/Grid";
import Button from "../components/Button";

const STATUS_OPTIONS: SelectOptions = [
  { label: "Active", value: "active" },
  { label: "Disabled", value: "disabled" }
];

const SCHEDULE_OPTIONS: SelectOptions = [{ label: "Hourly", value: "hourly" }];

const PRIORITY_OPTIONS: SelectOptions = [
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" }
];

const VIEW_OPTIONS: SelectOptions = [{ label: "A View", value: "1" }];

const JobBuilderPage: React.FC = () => {
  const [detector, setDetector] = useState("percentile");

  return (
    <Page>
      <form>
        <FormSection>
          <Title data-testid="page-title">Job Definition Builder</Title>
          <Subtitle>
            Build a batch of job definitions with common configuration &mdash; Start by defining the
            basics.
          </Subtitle>
          <FormSectionBody>
            <Grid columns="1fr 1fr 1fr" gutter="40px">
              <Span columns={2}>
                <Field id="group-name" label="Group Name" />
              </Span>
              <Field id="channel" label="Channel" />
              <Field id="status" label="Status" type="select" options={STATUS_OPTIONS} />
              <Field id="schedule" label="Schedule" type="select" options={SCHEDULE_OPTIONS} />
              <Field id="priority" label="Priority" type="select" options={PRIORITY_OPTIONS} />
            </Grid>
          </FormSectionBody>
        </FormSection>

        <FormSection>
          <Title>Data Source</Title>
          <Subtitle>
            Where should data be pulled from? &mdash; Pick a data source and then configure it’s
            options.
          </Subtitle>
          <FormSectionBody>
            <Grid columns="1fr 2fr" gutter="40px">
              <TabList>
                <Tab active>
                  <TabTitle>BigQuery View</TabTitle>
                  Select a pre-defined view in BigQuery as the source.
                </Tab>
              </TabList>
              <div>
                <Field id="view-name" label="View Name" type="select" options={VIEW_OPTIONS} />
              </div>
            </Grid>
          </FormSectionBody>
        </FormSection>

        <FormSection>
          <Title>Anomaly Detector</Title>
          <Subtitle>
            How should changes in the data be checked? &mdash; Pick an algorithm and then configure
            it’s options.
          </Subtitle>
          <FormSectionBody>
            <Grid columns="1fr 2fr" gutter="40px">
              <TabList>
                <Tab active={detector === "percentile"} onClick={() => setDetector("percentile")}>
                  <TabTitle>Percentile</TabTitle>
                  Detect anomaly based on confidence intervals on a student-t distribution.
                </Tab>
                <Tab active={detector === "absolute"} onClick={() => setDetector("absolute")}>
                  <TabTitle>Absolute Limit</TabTitle>
                  Detects anomalous data based on high/low limits given.
                </Tab>
              </TabList>
              {detector === "percentile" ? (
                <div>
                  <Field id="low-magnitude" label="Low Magnitude" />
                  <Field id="medium-magnitude" label="Medium Magnitude" />
                  <Field id="high-magnitude" label="High Magnitude" />
                </div>
              ) : (
                <div>
                  <Field id="high-limit" label="High Limit" />
                  <Field id="low-limit" label="Low Limit" />
                </div>
              )}
            </Grid>
          </FormSectionBody>
        </FormSection>

        <FormSection>
          <Title>Job Definitions List</Title>
          <Subtitle>
            You are about to create 8 new job definitions &mdash; See below for details.
          </Subtitle>
          <FormSectionBody>lorem ipsum</FormSectionBody>
        </FormSection>

        <Button type="submit">Create Job Definitions</Button>
      </form>
    </Page>
  );
};

export default JobBuilderPage;
