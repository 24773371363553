import { API_PROXY_URL } from "./config";
import { getToken } from "./token";
import {
  ApiResponse,
  Dictionary,
  JobsAPIParams,
  DeleteApiResponse,
  AlertsAPIParams,
  UsersAPIParams
} from "../types/api";

const apiURL = (path: string, params?: Dictionary): string => {
  const url = new URL(API_PROXY_URL);
  url.pathname = url.pathname + "/" + path;
  const token = getToken();
  if (token) url.searchParams.set("t", token);
  if (params)
    Object.keys(params).forEach(
      name =>
        params[name] != null && params[name] !== "" && url.searchParams.set(name, params[name])
    );
  return url.toString();
};

export const definitionUrl = (id: number | string) => apiURL(`definitions/${id}`);
export const definitionsUrl = () => apiURL("definitions");
export const timeseriesUrl = (id: number | string) => apiURL(`jobs/${id}/timeseries`);
export const jobUrl = (id: number | string) => apiURL(`jobs/${id}`);
export const jobsUrl = (params: JobsAPIParams = {}) => apiURL("jobs", params);
export const feedbackUrl = (id: number | string) => apiURL(`feedbacks/${id}`);
export const feedbacksUrl = () => apiURL(`feedbacks`);
export const usersUrl = (params: UsersAPIParams = {}) => apiURL(`users`, params);
export const userUrl = (idOrEmail: number | string) => apiURL(`users/${idOrEmail}`);
export const alertsUrl = (params: AlertsAPIParams = {}) => apiURL(`alerts`, params);
export const alertUrl = (id: number | string) => apiURL(`alerts/${id}`);
export const exportUrl = (exportName: string) => apiURL(`exports/${exportName}`);

export const get = async <T>(url: string): Promise<ApiResponse<T>> => {
  const response = await fetch(url);
  const json = await (response.json() as Promise<ApiResponse<T>>);
  return json;
};

export const post = async <T>(url: string, updates: Partial<T>): Promise<ApiResponse<T>> => {
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(updates),
    headers: { "Content-Type": "application/json" }
  });
  const json = await (response.json() as Promise<ApiResponse<T>>);
  return json;
};

export const patch = async <T, U = T>(
  url: string,
  updates: Partial<U>
): Promise<ApiResponse<T>> => {
  const response = await fetch(url, {
    method: "PATCH",
    body: JSON.stringify(updates),
    headers: { "Content-Type": "application/json" }
  });
  const json = await (response.json() as Promise<ApiResponse<T>>);
  return json;
};

export const destroy = async (url: string): Promise<DeleteApiResponse> => {
  const response = await fetch(url, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" }
  });
  const json = await (response.json() as Promise<DeleteApiResponse>);
  return json;
};
