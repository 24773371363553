import styled from "styled-components/macro";

export const Tab = styled.li<{ active?: boolean }>`
  margin: 10px 0;
  padding: 20px;
  background-color: ${({ active }) => (active ? "#f4f4f4" : "#f9f9f9")};
  border: ${({ active }) => (active ? "1px solid #c9c9c9" : "1px solid #fbfbfb")};
  border-radius: 4px;
  cursor: pointer;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background-color: #f4f4f4;
  }
`;

export const TabTitle = styled.h3`
  margin: 0;
  padding: 0 0 10px 0;
  font-size: 18px;
  font-weight: 600;
`;

const TabList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export default TabList;
