import React, { useCallback, useState, useMemo } from "react";

import Page, { Wrapper, ResponsiveFullBleed } from "../components/Page";
import JobsList from "../components/JobsList";
import { PageHeader, Tabs, Badge, Row, Statistic, Alert } from "antd";
import { useHistory, Route, useParams } from "react-router";
import { metricName } from "../lib/definition-utils";
import { Alert as TAlert, WritableAlertProps, AlertStatus } from "../types/api";
import { alertUrl, patch } from "../lib/api";
import AlertStatusSelect from "../components/AlertStatusSelect";
import UserSelect from "../components/UserSelect";
import JobFeedbackForm from "./JobFeedbackForm";
import DefinitionChart from "./DefinitionChart";
import { useMediaQuery } from "beautiful-react-hooks";
import { useUsersList, useAlert } from "../hooks/api";
import AlertNotificationsList from "../components/AlertNotificationsList";

const AlertPage: React.FC = () => {
  const history = useHistory();
  const { alertId } = useParams<{ alertId: string }>();
  const isDesktop = useMediaQuery("(min-width: 750px)");
  const [updatedAlert, setUpdatedAlert] = useState<TAlert | null>(null);
  const { users } = useUsersList();
  const { alert } = useAlert(alertId);

  const patchAlert = useCallback(
    async (changes: Partial<WritableAlertProps>) => {
      const { data: updatedAlertResponse } = await patch<TAlert, WritableAlertProps>(
        alertUrl(alertId),
        changes
      );
      setUpdatedAlert(updatedAlertResponse);
    },
    [alertId]
  );

  const allJobs = useMemo(() => {
    const currentAlert = updatedAlert || alert;
    if (currentAlert == null) return [];
    const jobsList = [currentAlert.primary_job, ...currentAlert.sub_jobs];
    return jobsList.sort((a, b) => b.id - a.id);
  }, [updatedAlert, alert]);

  return (
    <Page isLoading={alert === null} data={updatedAlert || alert}>
      {(alert: TAlert) => (
        <>
          <PageHeader
            title={`Alert #${alert.id}`}
            subTitle={alert.primary_job.definition.title}
            onBack={() => history.push("/alerts")}
            extra={[
              <AlertStatusSelect
                key="status"
                value={alert.current_status}
                onChange={v => patchAlert({ current_status: v as AlertStatus })}
              />,
              <UserSelect
                key="user"
                value={alert.assigned_user ? alert.assigned_user.id : undefined}
                onChange={value => patchAlert({ assigned_user_id: value })}
                onClear={() => patchAlert({ assigned_user_id: null })}
                users={users}
                clear="Remove Assignment"
                placeholder="Unassigned"
              />,
              isDesktop ? <JobFeedbackForm job={alert.primary_job} key="feedback" /> : null
            ]}
          >
            <Row type={isDesktop ? "flex" : undefined}>
              <Statistic
                title="Status"
                value={alert.current_status}
                valueStyle={{ fontSize: isDesktop ? "22px" : "18px" }}
                style={{
                  margin: isDesktop ? "20px 32px 20px 0" : "0 0 10px 0"
                }}
              />
              <Statistic
                title="Assigned To"
                value={alert.assigned_user ? alert.assigned_user.name : "Unassigned"}
                valueStyle={{ fontSize: isDesktop ? "22px" : "18px" }}
                style={{
                  margin: isDesktop ? "20px 32px 20px 0" : "0 0 10px 0"
                }}
              />
              <Statistic
                title="Definition"
                value={alert.primary_job.definition.title}
                valueStyle={{ fontSize: isDesktop ? "22px" : "18px" }}
                style={{
                  margin: isDesktop ? "20px 32px 20px 0" : "0 0 10px 0"
                }}
              />
              <Statistic
                title="Metric"
                value={metricName(alert.primary_job.definition)}
                valueStyle={{ fontSize: isDesktop ? "22px" : "18px" }}
                style={{
                  margin: isDesktop ? "20px 32px 20px 0" : "0 0 10px 0"
                }}
              />
              {alert.created_at && (
                <Statistic
                  title="Created"
                  value={new Date(alert.created_at).toLocaleTimeString()}
                  suffix={new Date(alert.created_at).toLocaleDateString()}
                  valueStyle={{ fontSize: isDesktop ? "22px" : "18px" }}
                  style={{
                    margin: isDesktop ? "20px 32px" : "0 0 10px 0"
                  }}
                />
              )}
            </Row>
          </PageHeader>
          <Wrapper>
            <ResponsiveFullBleed>
              {alert.primary_job.definition && (
                <DefinitionChart
                  definitionId={alert.primary_job.definition.id}
                  showOptions={false}
                  filterAnomalousMarksByJobId={allJobs.map(j => j.id)}
                />
              )}
              <Route
                path="/alerts/:alertId/:tab?"
                render={({ match, history }) => (
                  <Tabs
                    activeKey={match.params.tab || "jobs"}
                    onChange={key => history.push(`/alerts/${alertId}/${key}`)}
                    animated={false}
                  >
                    <Tabs.TabPane
                      tab={
                        <span>
                          Jobs
                          <Badge count={alert.sub_jobs.length + 1} style={{ marginLeft: "1em" }} />
                        </span>
                      }
                      key="jobs"
                    >
                      <JobsList jobs={allJobs} />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <span>
                          Notifications
                          {alert.notifications && (
                            <Badge
                              count={alert.notifications.length}
                              style={{ marginLeft: "1em" }}
                            />
                          )}
                        </span>
                      }
                      key="notifications"
                    >
                      {alert.notifications == null && (
                        <Alert
                          showIcon
                          message="If this definition is marked as noisy notifications may not be sent until more data is available."
                          style={{ marginBottom: 20 }}
                        />
                      )}
                      <AlertNotificationsList notifications={alert.notifications} />
                    </Tabs.TabPane>
                  </Tabs>
                )}
              />
            </ResponsiveFullBleed>
          </Wrapper>
        </>
      )}
    </Page>
  );
};

export default AlertPage;
