const loadFromEnv = (name: string, fallback: string | undefined = undefined) => {
  const envVar = process.env[`REACT_APP_${name}`] || fallback;
  if (envVar === undefined) throw new Error(`MISSING ${name}`);
  return envVar;
};

// note: this looks like a secret but it's just a client ID,
//       it ships to clients and is therefore safe to live in the repository
export const GOOGLE_OAUTH_CLIENT_ID = loadFromEnv(
  "GOOGLE_OAUTH_CLIENT_ID",
  "141343051190-tlpviaud7rbln8jkk1q8b6j6kqo6eaak.apps.googleusercontent.com"
);

export const API_PROXY_URL = loadFromEnv("API_PROXY_URL", "http://helios.lvh.me:3000/api");

const GA_TRACKING_IDS = {
  production: "UA-27821669-14",
  development: "UA-27821669-15",
  test: "UA-000000-01"
};

export const GA_TRACKING_ID = GA_TRACKING_IDS[process.env.NODE_ENV];
