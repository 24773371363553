import { Dictionary } from "../types/api";

// Given an Object and an array of keys return an Object of only the given keys
// pluck({ a: 1, b: 2 }, ["a"]) => { a: 1 }
export const pluck = <T extends Dictionary, K extends keyof T>(obj: T, keys: K[]): Partial<T> =>
  keys.reduce((acc, key) => {
    acc[key] = obj[key];
    return acc;
  }, {} as Partial<T>);

export const rejectNulls = <T extends Dictionary, K extends keyof T>(obj: T): Partial<T> =>
  Object.entries(obj).reduce((acc, [k, v]) => (v == null ? acc : { ...acc, [k]: v }), {});

const formatter = new Intl.NumberFormat("en-GB");

export const formatNumber = (n: number): string => formatter.format(n);

// unions 2 lists of the same type, into one list of unique elements
// comparison is done using equality + shallowEqual check for objects
export const union = <T extends any>(arr1: T[], arr2: T[]): T[] => {
  const newEntries = arr2.filter(el => arr1.find(x => shallowCompare(el, x)) === undefined);
  return [...arr1, ...newEntries];
};

// shallow compare the properties of 2 Objects
export const shallowCompare = (obj1: any, obj2: any): boolean =>
  obj1 === obj2 ||
  (typeof obj1 === "object" &&
    typeof obj2 === "object" &&
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every(key => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]));
