import React from "react";
import styled from "styled-components/macro";

import DefinitionsTable from "./DefinitionsTable";
import { Definitions, Definition } from "../types/api";

const DefinitionGroupContainer = styled.div`
  padding: 20px 0 20px 0;
`;

const GroupName = styled.h3`
  margin: 0;
  padding: 0 0 20px 0;
  font-size: 18px;
  font-weight: 600;
`;

const grouped = (definitions: Definitions): Definitions[] => {
  const emptyGroupable: { [key: string]: Definitions } = {};
  const grouped = definitions.reduce((r, d) => {
    const groupName = d.batch_creation_name;
    r[groupName] = r[groupName] || [];
    r[groupName].push(d);
    return r;
  }, emptyGroupable);
  return Object.values(grouped);
};

const groupName = (definition?: Definition): string =>
  definition?.batch_creation_name ?? "Unititled";

const DefinitionGroup: React.FC<{ definitions: Definitions }> = ({ definitions }) => (
  <DefinitionGroupContainer>
    <GroupName>{groupName(definitions[0])}</GroupName>
    <DefinitionsTable definitions={definitions} />
  </DefinitionGroupContainer>
);

const DefinitionList: React.FC<{ definitions: Definitions }> = ({ definitions }) => (
  <div>
    {grouped(definitions).map(group => (
      <DefinitionGroup key={groupName(group[0])} definitions={group} />
    ))}
  </div>
);

export default DefinitionList;
