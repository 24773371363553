import React from "react";
import TimeseriesChart from "../components/TimeseriesChart";
import { metricName, normaliseGrain } from "../lib/definition-utils";
import { useParams } from "react-router";
import { setTokenFromQuery } from "../lib/token";
import styled from "styled-components";
import { Result } from "antd";
import { useTimeseries, useJob } from "../hooks/api";

const ChartContainer = styled.div`
  padding: 20px;
  background-color: #fff;
`;

const MessageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
`;

const Message: React.FC<{ title: string; subTitle: string; status?: "error" | "info" }> = ({
  title,
  subTitle,
  status = "error"
}) => (
  <MessageWrapper>
    <Result status={status} title={title} subTitle={subTitle} />
  </MessageWrapper>
);

const JobChart: React.FC = () => {
  setTokenFromQuery();
  const { jobId } = useParams<{ jobId: string }>();
  const { error, job, response, isLoadingJob } = useJob(jobId);
  const { timeseries, isLoadingTimeseries } = useTimeseries(jobId);

  if (isLoadingJob || isLoadingTimeseries) {
    return (
      <Message
        status="info"
        title="Preparing..."
        subTitle={`Job: ${isLoadingJob ? "loading" : "loaded"}, Timeseries: ${
          isLoadingTimeseries ? "loading" : "loaded"
        }`}
      />
    );
  }

  if (error) {
    return <Message title="An Error Occurred" subTitle={error.toString()} />;
  }

  if (job == null) {
    return <Message title="No response" subTitle="The Driver failed to respond" />;
  }

  if (response && response.status === "fail") {
    return <Message title="Driver Job Request Failed" subTitle={JSON.stringify(response)} />;
  }

  if (timeseries === null) {
    return (
      <Message title="missing Timeseries" subTitle={`Job ${jobId} has no timeseries data yet`} />
    );
  }

  return (
    <ChartContainer>
      <TimeseriesChart
        includeChrome={false}
        timeseries={timeseries}
        metricName={metricName(job.definition)}
        grain={normaliseGrain(job.definition)}
      />
    </ChartContainer>
  );
};

export default JobChart;
