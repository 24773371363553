import styled from "styled-components";

export const TableFilterForm = styled.div`
  margin-bottom: 1em;
  @media screen and (min-width: 750px) {
    display: flex;
    justify-content: space-between;
    margin: 0 0 1em 0;
  }
`;
