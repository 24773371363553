import React, { useState, useCallback } from "react";
import { Job, Usefulness, Feedback, Feedbacks } from "../types/api";
import { Select, Modal, Form, Input, Radio } from "antd";
import { feedbacksUrl, post } from "../lib/api";
import { getProfile } from "../lib/token";
import { FEEDBACK_USEFULNESS } from "../lib/feedback";
import { trackFeedbackEvent } from "../lib/ga";

const saveFeedback = async (
  jobId: number,
  updates: Partial<Feedback>,
  setIsSaving: (s: boolean) => void
): Promise<Boolean> => {
  setIsSaving(true);
  const result = await post<Feedback>(feedbacksUrl(), { ...updates, job_id: jobId });
  setIsSaving(false);
  return result.status === "success";
};

export const findFeedback = (feedbacks: Feedbacks | null, email: string | null): Feedback | null =>
  (email && feedbacks && feedbacks.find(f => f.created_by === email)) || null;

interface JobFeedbackFormProps {
  job: Job;
  noDefaultOpen?: boolean;
}

const JobFeedbackForm: React.FC<JobFeedbackFormProps> = ({ job, noDefaultOpen }) => {
  const profile = getProfile();
  const feedback = findFeedback(job.feedbacks, profile ? profile.email : null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const url = new URL(window.location.href);
  const defaultModalState = url.searchParams.get("submitFeedback") === "true";
  const [isModalOpen, setIsModalOpen] = useState<boolean>(
    noDefaultOpen ? false : defaultModalState
  );
  const [formData, setFormData] = useState<Partial<Feedback>>(feedback || {});
  const [usefulnessValue, setUsefulnessValue] = useState<undefined | Usefulness | "more">(
    feedback ? feedback.usefulness : undefined
  );

  const updateFormData = useCallback(
    (updates: Partial<Feedback>) => {
      setFormData({ ...formData, ...updates });
    },
    [setFormData, formData]
  );

  const saveUsefulness = useCallback(
    (usefulness: Usefulness | "more") => {
      if (usefulness === "more") {
        const oldUsefulnessValue = usefulnessValue;
        setIsModalOpen(true);
        setUsefulnessValue(undefined);
        setUsefulnessValue(oldUsefulnessValue);
      } else {
        setUsefulnessValue(usefulness);
        updateFormData({ usefulness });
        profile && saveFeedback(job.id, { usefulness, created_by: profile.email }, setIsSaving);
        trackFeedbackEvent("quick", usefulness || "not_given", job.id);
      }
    },
    [setIsModalOpen, setUsefulnessValue, setIsSaving, profile, job, updateFormData, usefulnessValue]
  );

  const saveForm = useCallback(() => {
    if (profile) {
      saveFeedback(job.id, { ...formData, created_by: profile.email }, saving => {
        setIsSaving(saving);
        if (!saving) setIsModalOpen(false);
        setUsefulnessValue(formData.usefulness);
      });
    }
    trackFeedbackEvent("full", formData.usefulness || "not_given", job.id);
  }, [profile, job, formData, setIsSaving, setIsModalOpen, setUsefulnessValue]);

  if (profile == null) return <div></div>;
  return (
    <>
      <Select
        placeholder="How useful was this?"
        style={{ width: 180 }}
        onChange={saveUsefulness}
        disabled={profile == null}
        loading={isSaving}
        value={usefulnessValue}
      >
        {Object.keys(FEEDBACK_USEFULNESS).map(value => (
          <Select.Option key={value} value={value}>
            {FEEDBACK_USEFULNESS[value as Usefulness]}
          </Select.Option>
        ))}
        <Select.Option value="more">More Feedback...</Select.Option>
      </Select>

      <Modal
        visible={isModalOpen}
        title={`Feedback for Job #${job.id}`}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => saveForm()}
        okText="Save"
      >
        <Form>
          <Form.Item label="How useful was this?">
            <Radio.Group
              buttonStyle="solid"
              value={formData.usefulness}
              onChange={e => updateFormData({ usefulness: e.target.value })}
            >
              <Radio.Button value="not_at_all">Not At All Useful</Radio.Button>
              <Radio.Button value="somewhat">Somewhat Useful</Radio.Button>
              <Radio.Button value="very">Very Useful</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Mark result as">
            <Radio.Group
              buttonStyle="solid"
              value={formData[job.anomalous ? "positive" : "negative"]}
              onChange={e =>
                updateFormData({ [job.anomalous ? "positive" : "negative"]: e.target.value })
              }
            >
              <Radio.Button value={true}>
                True {job.anomalous ? "Positive" : "Negative"}
              </Radio.Button>
              <Radio.Button value={false}>
                False {job.anomalous ? "Positive" : "Negative"}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Additional Notes">
            <Input.TextArea
              rows={5}
              value={formData.notes}
              onChange={e => updateFormData({ notes: e.target.value })}
            ></Input.TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default JobFeedbackForm;
