import React, { useState, useMemo } from "react";
import { Timeseries, Dictionary, DataGetterPeriod } from "../types/api";
import styled from "styled-components";

import { Chart, Geom, Axis, Tooltip, Legend, View } from "bizcharts";
import DataSet from "@antv/data-set";
import { Radio, Dropdown, Button, Icon, Menu } from "antd";
import { useMediaQuery, useWindowResize, useDebouncedFn } from "beautiful-react-hooks";

const MAX_POINTS = 2500;

const MARK_COLORS: Dictionary = {
  high: "#ff7f00",
  low: "#9A7EFF",
  normal: "#6c6",
  incomplete: "#ddd"
};
const NORMAL_STATUSES = ["normal", "incomplete"];

const buildData = (
  timeseries: Timeseries,
  metricName: string,
  anomalousMarks: Dictionary,
  endNow: boolean,
  grain: DataGetterPeriod
): Dictionary => {
  const anomDate = new Date(
    timeseries.data.datetime[timeseries.data.datetime.length - 1].replace(" ", "T")
  );
  let datetimes = timeseries.data.datetime;
  let metrics = timeseries.data.metric;
  if (datetimes.length > MAX_POINTS) {
    datetimes = datetimes.slice(datetimes.length - MAX_POINTS);
    metrics = metrics.slice(metrics.length - MAX_POINTS);
  }
  const data = datetimes.map((datetime, i) => {
    const date = new Date(datetime.replace(" ", "T"));
    const isMatchingPeriod =
      grain === "HOUR"
        ? date.getHours() === anomDate.getHours()
        : date.getDay() === anomDate.getDay();
    return {
      // note: some historic timeseries data isn't correctly ISO formatted
      date: datetime.replace(" ", "T"),
      value: metrics[i],
      matchingPeriod: isMatchingPeriod ? metrics[i] : undefined,
      metricName: metricName,
      anomalousMark: anomalousMarks[datetime] || undefined,
      full: 1
    };
  });

  const ds = new DataSet({
    state: {
      originalStart: new Date(data[0].date).getTime(),
      originalEnd: new Date(data[data.length - 1].date).getTime(),
      start: new Date(data[0].date).getTime(),
      end: endNow ? new Date().getTime() : new Date(data[data.length - 1].date).getTime(),
      showNormalRunMarks: false
    }
  });

  const windowed = ds.createView("windowed").source(data);
  windowed.transform({
    type: "filter",

    callback(obj: { date: Date; value: number }) {
      const time = new Date(obj.date).getTime();

      return time >= ds.state.start && time <= ds.state.end;
    }
  });

  const matchingPeriod = ds.createView("matchingPeriod").source(windowed);
  matchingPeriod.transform({
    type: "filter",

    callback(obj: { date: Date; matchingPeriod: number | undefined }) {
      return obj.matchingPeriod !== undefined;
    }
  });

  const anomalous = ds.createView("anomalous").source(windowed);
  anomalous.transform({
    type: "filter",

    callback(obj: { date: Date; anomalousMark: string | undefined }) {
      if (obj.anomalousMark === undefined) return false;
      return ds.state.showNormalRunMarks || !NORMAL_STATUSES.includes(obj.anomalousMark);
    }
  });

  return ds;
};

const startDateForMode = (days: number, start: number, end: number) => {
  if (days === 0) return new Date(start).getTime();
  let t = new Date(end);
  t.setDate(t.getDate() - days);
  return t.getTime();
};

const tickCountForMode = (days: number) => {
  if (days > 14) return 15;
  if (days < 7) return 8;
  return days;
};

interface TimeseriesChartProps {
  timeseries: Timeseries;
  metricName: string;
  grain: DataGetterPeriod;
  width?: number;
  anomalousMarks?: Dictionary;
  endNow?: boolean;
  defaultShowMatchingPeriod?: boolean;
  includeChrome?: boolean;
  showOptions?: boolean;
}

const ChartContainer = styled.div`
  margin: 0 0 40px 0;
`;

const Controls = styled.div`
  margin: 20px;
  text-align: center;
`;

const MoreOptions = styled.div`
  display: inline-block;
  padding-left: 20px;
`;

const getWidth = (maxWidth: number, isDesktop: boolean) => {
  if (window.innerWidth > maxWidth) return maxWidth;
  const padding = isDesktop ? 40 : 0;
  return window.innerWidth - padding;
};

const TimeseriesChart: React.FC<TimeseriesChartProps> = ({
  timeseries,
  metricName,
  grain,
  width: maxWidth = 1160,
  anomalousMarks = [],
  endNow = false,
  defaultShowMatchingPeriod = true,
  includeChrome = true,
  showOptions = true
}) => {
  const isDesktop = useMediaQuery("(min-width: 720px)");
  const [width, setWidth] = useState(getWidth(maxWidth, isDesktop));

  useWindowResize(
    // @ts-ignore
    useDebouncedFn(
      () => {
        setWidth(getWidth(maxWidth, isDesktop));
      },
      250,
      undefined,
      [setWidth, maxWidth, isDesktop]
    )
  );

  const [mode, setMode] = useState<number>(grain === "HOUR" ? 7 : 60);
  const [[minX, maxX], setMinMaxX] = useState<[number, number]>([0, 0]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [showMatchingPeriod, setShowMatchingPeriod] = useState<boolean>(defaultShowMatchingPeriod);
  const [showNormalRunMarks, setShowNormalRunMarks] = useState<boolean>(false);
  const ds = useMemo(() => {
    const ds = buildData(timeseries, metricName, anomalousMarks, endNow, grain);
    ds.on("statechange", () => {
      const values = ds.getView("windowed").rows.map((r: Dictionary) => r.value);
      const min = Math.min(...values);
      let max = Math.max(...values);
      if (max <= 0) {
        max = 1;
      }
      const count = ds.getView("windowed").rows.length;

      if (minX !== min || maxX !== max) setMinMaxX([min, max]);
      if (rowCount !== count) setRowCount(count);
    });
    return ds;
  }, [
    timeseries,
    metricName,
    setMinMaxX,
    anomalousMarks,
    endNow,
    grain,
    minX,
    maxX,
    setRowCount,
    rowCount
  ]);

  const startDate = startDateForMode(mode, ds.state.originalStart, ds.state.end);
  ds.setState("start", startDate);
  ds.setState("showNormalRunMarks", showNormalRunMarks);

  const setDays = (d: number) => {
    if (mode === d) return;
    setMinMaxX([0, 0]);
    setMode(d);
  };

  const showAnomalousMarks = Object.keys(anomalousMarks).length > 0;
  const scale = {
    value: {
      nice: true,
      alias: metricName,
      min: minX,
      max: maxX
    },
    matchingPeriod: {
      nice: true,
      alias: `Matching ${grain === "HOUR" ? "Hour" : "Day"}`,
      min: minX,
      max: maxX
    },
    full: {
      min: 0,
      max: 1
    },
    date: {
      alias: grain === "HOUR" ? "Hour" : "Date",
      min: ds.state.start,
      max: ds.state.end,
      tickCount: isDesktop ? tickCountForMode(mode) : 5,
      type: "time",
      mask: grain === "HOUR" ? "MMM D @ HH:mm" : "MMM D"
    }
  };

  return (
    <>
      {includeChrome && (
        <Controls>
          <Radio.Group value={mode}>
            {grain === "DAY" && (
              <>
                <Radio.Button value={90} onClick={() => setDays(90)}>
                  90 Days
                </Radio.Button>
                <Radio.Button value={60} onClick={() => setDays(60)}>
                  60 Days
                </Radio.Button>
              </>
            )}
            <Radio.Button value={30} onClick={() => setDays(30)}>
              30 Days
            </Radio.Button>
            {grain === "HOUR" && (
              <>
                <Radio.Button value={14} onClick={() => setDays(14)}>
                  14 Days
                </Radio.Button>
                <Radio.Button value={7} onClick={() => setDays(7)}>
                  7 Days
                </Radio.Button>
                <Radio.Button value={2} onClick={() => setDays(2)}>
                  48 {isDesktop ? "Hours" : "Hr"}
                </Radio.Button>
              </>
            )}
          </Radio.Group>
          {isDesktop && showOptions && (
            <MoreOptions>
              <Dropdown
                placement="bottomRight"
                overlay={
                  <Menu>
                    <Menu.Item key="0" onClick={() => setShowMatchingPeriod(!showMatchingPeriod)}>
                      {showMatchingPeriod ? "Hide" : "Show"} Matching{" "}
                      {grain === "HOUR" ? "Hour" : "Day"}
                    </Menu.Item>
                    {showAnomalousMarks && (
                      <Menu.Item key="1" onClick={() => setShowNormalRunMarks(!showNormalRunMarks)}>
                        {showNormalRunMarks ? "Hide" : "Show"} Normal Detector Runs
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button>
                  View Options <Icon type="down" />
                </Button>
              </Dropdown>
            </MoreOptions>
          )}
        </Controls>
      )}
      <ChartContainer>
        <Chart
          height={isDesktop ? 450 : 240}
          width={width}
          scale={scale}
          animate={false}
          padding={"auto"}
        >
          <Tooltip crosshairs={{ type: "y" }} />
          <Legend />

          <View data={ds.getView("windowed")} scale={scale}>
            <Axis name="date" />
            <Axis name="value" title={isDesktop} />

            {maxX > 0 && <Geom type="line" position="date*value" size={2} color="metricName" />}
            {isDesktop && maxX > 0 && rowCount < 200 && (
              <Geom
                type="point"
                position="date*value"
                size={3}
                shape="circle"
                color="metricName"
                style={{
                  stroke: "#fff",
                  lineWidth: 1
                }}
              />
            )}
          </View>

          {showAnomalousMarks && (
            <View data={ds.getView("anomalous")} scale={scale}>
              <Geom
                type="interval"
                position="date*full"
                color={["anomalousMark", m => MARK_COLORS[m]]}
                size={1}
              />
            </View>
          )}

          {showMatchingPeriod && (
            <View data={ds.getView("matchingPeriod")} scale={scale}>
              {maxX > 0 && (
                <Geom
                  type="line"
                  position="date*matchingPeriod"
                  size={1}
                  color="#FF3FE8"
                  shape="spline"
                  style={{ lineDash: [0, 4, 2] }}
                />
              )}
              {maxX > 0 && (
                <Geom
                  type="point"
                  position="date*matchingPeriod"
                  size={3}
                  shape="circle"
                  color="#FF3FE8"
                  style={{
                    stroke: "#fff",
                    lineWidth: 1
                  }}
                />
              )}
            </View>
          )}
        </Chart>
      </ChartContainer>
    </>
  );
};

export default TimeseriesChart;
