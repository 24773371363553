import React from "react";

import { GoogleLogin, GoogleLoginResponse } from "react-google-login";
import styled from "styled-components";
import { GOOGLE_OAUTH_CLIENT_ID } from "../lib/config";

const CenteredContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
`;

const CenteredContent = styled.div`
  margin: auto;
`;

const Centered: React.FC = ({ children }) => (
  <CenteredContainer>
    <CenteredContent>{children}</CenteredContent>
  </CenteredContainer>
);

type LoginResp = GoogleLoginResponse | null;

const LoginPage: React.FC<{ onLogin: (r: LoginResp) => void }> = ({ onLogin }) => {
  return (
    <Centered>
      <div style={{ margin: "auto" }}>
        <GoogleLogin
          clientId={GOOGLE_OAUTH_CLIENT_ID}
          buttonText="Login"
          onSuccess={resp => onLogin(resp as GoogleLoginResponse)}
          onFailure={_resp => onLogin(null)}
          cookiePolicy={"single_host_origin"}
          isSignedIn={true}
        />
      </div>
    </Centered>
  );
};

export default LoginPage;
