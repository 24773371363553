import React from "react";

import Page, { Title, Header } from "../components/Page";

const TriagePage: React.FC = () => {
  return (
    <Page>
      <Header>
        <Title data-testid="page-title">Triage</Title>
      </Header>
    </Page>
  );
};

export default TriagePage;
