import React, { useCallback, useState } from "react";
import { Select } from "antd";
import { Users, User } from "../types/api";

interface UserSelectProps {
  value: number | undefined;
  users: Users;
  onChange: (value: number) => void;
  onClear?: () => void;
  clear?: boolean | string;
  placeholder?: string;
}

export const nameSorter = (a: User, b: User) => {
  var nameA = a.name.toLowerCase();
  var nameB = b.name.toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

const UserSelect: React.FC<UserSelectProps> = ({
  value,
  onChange,
  users,
  onClear = () => null,
  clear = false,
  placeholder = undefined
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const changeHandler = useCallback(
    (value: string | number) => {
      if (value === "__clear__") {
        setCurrentValue(undefined);
        onClear();
      } else {
        setCurrentValue(value as number);
        onChange(value as number);
      }
    },
    [onChange, onClear]
  );
  return (
    <Select
      placeholder={placeholder || clear || ""}
      style={{ width: 120 }}
      onChange={changeHandler}
      value={currentValue}
    >
      {clear && currentValue && (
        <Select.Option key="clear" value="__clear__">
          <strong>{clear === true ? "Clear" : clear}</strong>
        </Select.Option>
      )}
      {users.sort(nameSorter).map(user => (
        <Select.Option key={user.id} value={user.id}>
          {user.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default UserSelect;
