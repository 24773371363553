import React, { useState, useCallback } from "react";
import { useMediaQuery } from "beautiful-react-hooks";

import Page, { Wrapper } from "../components/Page";
import AlertStatusSelect, {
  ALL_ALERT_STATUS,
  AlertStatusOption,
  ALL_OPEN_ALERT_STATUS
} from "../components/AlertStatusSelect";
import { Table, Badge, Form, Radio, Popover, Tag, Empty } from "antd";
import { useHistory } from "react-router";
import { History } from "history";
import {
  AlertStatus,
  ListAlertsResponse,
  Alert,
  WritableAlertProps,
  Job,
  Feedback,
  Usefulness
} from "../types/api";
import { metricName, cutsForDisplay, metaForDisplay } from "../lib/definition-utils";
import { alertsUrl, alertUrl, patch, post, feedbacksUrl, destroy, feedbackUrl } from "../lib/api";
import useFetch from "react-fetch-hook";
import UserSelect from "../components/UserSelect";
import { RelativeTime } from "../components/RelativeTime";
import { timingInfo } from "../components/JobsList";
import { navigateTo } from "../lib/navigation";
import DimensionPill from "../components/DimensionPill";
import VoteWidget, { Direction } from "../components/VoteWidget";
import { getProfile } from "../lib/token";
import InlineAlertRow from "./InlineAlertRow";
import { TableFilterForm } from "../components/TableFilterForm";
import { useUsersList } from "../hooks/api";
import NotificiationSelect, { NotificationStatusValue } from "../components/NotificationSelect";

const PAGE_SIZE = 50;

const createdAtField = (created_at: string, alert: Alert) => {
  return (
    <Popover
      placement="topRight"
      title="Primary Job Timing Info"
      content={timingInfo(alert.primary_job)}
      overlayStyle={{ maxWidth: 300 }}
    >
      <span>
        <RelativeTime date={new Date(created_at)} />
      </span>
    </Popover>
  );
};

const changeFilter = (history: History<any>, field: string, value: any) => {
  const params = new URLSearchParams(history.location.search);
  if (value === null || value === "") {
    params.delete(field);
  } else {
    params.set(field, value.toString());
  }
  history.push(history.location.pathname + "?" + params.toString());
};

const AlertsPage: React.FC = () => {
  const profile = getProfile();
  const history = useHistory();
  const isDesktop = useMediaQuery("(min-width: 750px)");
  const params = new URLSearchParams(history.location.search);
  const currentStatus =
    (params.get("current_status") as AlertStatusOption) || ALL_OPEN_ALERT_STATUS;
  const assignedToUserId = params.get("assigned_to_user_id")
    ? Number(params.get("assigned_to_user_id"))
    : null;
  const hasNotifications = params.get("has_notifications") as NotificationStatusValue;
  const hours = params.get("hours") ? Number(params.get("hours")) : 6;
  const [page, setPage] = useState<number>(1);
  const { users } = useUsersList();
  const { data: alertsResponse } = useFetch<ListAlertsResponse>(
    alertsUrl({
      current_status: currentStatus,
      assigned_to_user_id: assignedToUserId,
      has_notifications: hasNotifications === "" ? null : hasNotifications,
      since: hours * 60 * 60,
      page: page,
      page_size: PAGE_SIZE,
      order_by: "updated_at_desc"
    }),
    { depends: [page] }
  );

  const patchAlert = async (alertId: number, changes: Partial<WritableAlertProps>) => {
    await patch<WritableAlertProps>(alertUrl(alertId), changes);
  };

  const alerts = alertsResponse ? alertsResponse.data : undefined;

  const saveVoteResponse = useCallback((direction: Direction, job: Job) => {
    const usefulness: Usefulness = direction === "up" ? "very" : "not_at_all";
    const profile = getProfile();
    if (profile) {
      post<Feedback>(feedbacksUrl(), { usefulness, created_by: profile.email, job_id: job.id });
    }
  }, []);

  const clearVoteResponse = useCallback((feedback: Feedback) => {
    destroy(feedbackUrl(feedback.id));
  }, []);

  return (
    <Page isLoading={!alerts} data={alerts}>
      <Wrapper>
        <TableFilterForm>
          <h2>Alerts</h2>
          <Form layout="inline">
            <Form.Item>
              <NotificiationSelect
                onChange={v => changeFilter(history, "has_notifications", v)}
                value={hasNotifications || ""}
              />
            </Form.Item>
            <Form.Item>
              <AlertStatusSelect
                onChange={(v: AlertStatusOption) =>
                  changeFilter(history, "current_status", v === ALL_ALERT_STATUS ? null : v)
                }
                value={currentStatus}
                includeAllOption
                includeAllOpen
              />
            </Form.Item>
            <Form.Item>
              <UserSelect
                onChange={v => changeFilter(history, "assigned_to_user_id", v)}
                onClear={() => changeFilter(history, "assigned_to_user_id", null)}
                value={assignedToUserId || undefined}
                users={users}
                clear="All Users"
              />
            </Form.Item>
            <Form.Item>
              <Radio.Group
                buttonStyle="solid"
                value={hours}
                onChange={e => changeFilter(history, "hours", e.target.value)}
              >
                <Radio.Button value={1}>1 hour</Radio.Button>
                <Radio.Button value={6}>6 hours</Radio.Button>
                <Radio.Button value={24}>24 hours</Radio.Button>
                <Radio.Button value={7 * 24}>7 days</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Form>
        </TableFilterForm>
      </Wrapper>
      <Table
        rowKey="id"
        dataSource={alerts}
        pagination={{
          current: page,
          pageSize: PAGE_SIZE,
          total: alertsResponse ? alertsResponse.total_count : 0,
          onChange: setPage
        }}
        size="middle"
        onRow={(alert, _idx) => ({ onClick: navigateTo(history, `/alerts/${alert.id}`) })}
        expandedRowRender={isDesktop ? alert => <InlineAlertRow alertId={alert.id} /> : undefined}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Matching Alerts" />
        }}
        scroll={{ x: !isDesktop }}
      >
        {isDesktop && (
          <Table.Column
            key="id"
            title="Alert ID"
            dataIndex="id"
            render={value => (
              <>
                #<strong>{value}</strong>
              </>
            )}
          />
        )}
        <Table.Column
          key="feedback"
          title="Vote"
          dataIndex=""
          render={(_, alert: Alert) => (
            <div onClick={e => e.stopPropagation()}>
              <VoteWidget
                job={alert.primary_job}
                onVote={saveVoteResponse}
                onRemove={clearVoteResponse}
                email={profile && profile.email}
              />
            </div>
          )}
        />
        <Table.Column
          key="direction"
          title="Anom"
          dataIndex="primary_job.direction"
          render={direction => (
            <Tag color={direction === "high" ? "orange" : "cyan"}>{direction}</Tag>
          )}
        />
        <Table.Column
          key="definition.title"
          title="Definition"
          dataIndex="primary_job.definition.title"
        />
        <Table.Column
          key="metric_name"
          dataIndex="primary_job.definition"
          title="Metric"
          render={definition => metricName(definition)}
        />
        <Table.Column
          key="cuts"
          dataIndex="primary_job.definition"
          title="Cuts"
          render={definition =>
            (definition.data_getter_arguments.cuts
              ? cutsForDisplay(definition.data_getter_arguments.cuts)
              : metaForDisplay(definition)
            ).map(([name, value]) => <DimensionPill key={name} name={name} value={value} />)
          }
        />
        <Table.Column
          key="status"
          title="Status"
          dataIndex="current_status"
          render={(current_status, alert: Alert) => (
            <div onClick={e => e.stopPropagation()}>
              <AlertStatusSelect
                value={alert.current_status}
                onChange={value => patchAlert(alert.id, { current_status: value as AlertStatus })}
              />
            </div>
          )}
        />
        <Table.Column
          key="assignee"
          title="Assigned To"
          dataIndex="assigned_user"
          render={(assigned_user, alert: Alert) => (
            <div onClick={e => e.stopPropagation()}>
              <UserSelect
                onChange={value => patchAlert(alert.id, { assigned_user_id: value })}
                onClear={() => patchAlert(alert.id, { assigned_user_id: null })}
                value={assigned_user ? assigned_user.id : undefined}
                users={users}
                clear="Remove Assignment"
                placeholder="Unassigned"
              />
            </div>
          )}
        />
        <Table.Column
          key="job_count"
          title="Jobs"
          dataIndex="sub_jobs.length"
          render={subJobCount => <Badge count={subJobCount + 1} />}
        />
        <Table.Column key="created_at" title="Ago" dataIndex="created_at" render={createdAtField} />
      </Table>
    </Page>
  );
};

export default AlertsPage;
