import React, { useState } from "react";
import { JobsAPIParams } from "../types/api";
import LoadingSpinner from "../components/LoadingSpinner";
import { Radio, Switch, Form } from "antd";
import JobsList from "../components/JobsList";
import { TableFilterForm } from "../components/TableFilterForm";
import { ResponsiveFullBleed } from "../components/Page";
import { useMediaQuery } from "beautiful-react-hooks";
import { useJobsList } from "../hooks/api";

interface RecentJobsListProps {
  definitionId?: string | number;
  title?: string;
  defaultAnomalousOnly?: boolean;
}

const RecentJobsList: React.FC<RecentJobsListProps> = ({
  title = "Jobs",
  definitionId,
  defaultAnomalousOnly = false
}) => {
  const [page, setPage] = useState<number>(1);
  const [direction, setDirection] = useState<"high" | "low" | "">("");
  const [anomalous, setAnomalous] = useState<boolean>(defaultAnomalousOnly);
  const isDesktop = useMediaQuery("(min-width: 720px)");

  const urlParams: JobsAPIParams = {
    page_size: 30,
    order_by: "created_at_desc",
    direction: anomalous && direction !== "" ? direction : undefined,
    anomalous: anomalous ? true : undefined,
    recent: true,
    page
  };
  if (definitionId) urlParams.definition_id = definitionId;
  const { jobs, isLoadingJobsList, totalCount } = useJobsList(urlParams);

  return (
    <>
      <TableFilterForm>
        <h2>{title}</h2>
        <Form layout="inline">
          <Form.Item label="Anomalous" style={{ maxWidth: isDesktop ? "auto" : "30%" }}>
            <Switch checked={anomalous} onChange={setAnomalous} />
          </Form.Item>
          <Form.Item label="Direction" style={{ maxWidth: isDesktop ? "auto" : "60%" }}>
            <Radio.Group
              buttonStyle="solid"
              value={direction}
              onChange={e => setDirection(e.target.value)}
              disabled={!anomalous}
            >
              <Radio.Button value="">Any</Radio.Button>
              <Radio.Button value="high">High</Radio.Button>
              <Radio.Button value="low">Low</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      </TableFilterForm>

      {isLoadingJobsList || jobs == null ? (
        <LoadingSpinner />
      ) : (
        <ResponsiveFullBleed>
          <JobsList
            jobs={jobs}
            pagination={{
              current: page,
              pageSize: 30,
              total: totalCount,
              onChange: p => setPage(p)
            }}
          />
        </ResponsiveFullBleed>
      )}
    </>
  );
};

export default RecentJobsList;
