import React from "react";
import { AlertNotification } from "../types/api";
import { Table, Tag, Empty } from "antd";
import { dateTimeFormat } from "../lib/datetime";

interface AlertNotificationsListProps {
  notifications: AlertNotification[] | null;
}

const renderType = (t: string) => <Tag>{t.replace(/_/, " ")}</Tag>;

const AlertNotificationsList: React.FC<AlertNotificationsListProps> = ({ notifications }) => {
  return (
    <Table
      rowKey="create_at"
      dataSource={notifications || []}
      pagination={false}
      size="middle"
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Notifications" />
      }}
    >
      <Table.Column
        key="notification_type"
        title="Type"
        dataIndex="notification_type"
        render={renderType}
      />
      <Table.Column key="slack_channel" title="Slack Channel" dataIndex="slack_channel" />
      <Table.Column key="user_name" title="User" dataIndex="user_name" />
      <Table.Column
        key="created_at"
        title="Created at"
        dataIndex="created_at"
        render={dateTimeFormat}
      />
    </Table>
  );
};

export default AlertNotificationsList;
