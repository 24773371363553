import React, { useState, useEffect, useCallback } from "react";

import Page, { Wrapper, ResponsiveFullBleed } from "../components/Page";
import { definitionUrl, patch, get } from "../lib/api";
import { useParams, useHistory, Route } from "react-router";
import { Definition, ErrorDictionary, DefinitionEdits } from "../types/api";
import { pluck } from "../lib/utils";
import { metaForDisplay, cutsForDisplay, metricName } from "../lib/definition-utils";
import RecentJobsList from "./RecentJobsList";
import DefinitionSummary from "../components/DefinitionSummary";
import { PageHeader, Button, Tabs, Row, Statistic, Modal } from "antd";
import DefinitionChart from "./DefinitionChart";

const DefinitionPage: React.FC = () => {
  const history = useHistory();
  const { definitionId } = useParams<{ definitionId: string }>();
  const [data, setData] = useState<Definition | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorDictionary>({});
  const loadDefinition = async (definitionId: string) => {
    const result = await get<Definition>(definitionUrl(definitionId));
    setData(result.data);
  };

  const saveDefinition = useCallback(
    async (updates: Partial<Definition>) => {
      setIsSaving(true);
      const result = await patch<Definition>(definitionUrl(definitionId), updates);
      if (result.status === "success") {
        setData(result.data);
      } else {
        result.errors && setErrors(result.errors[0]);
      }
      setIsSaving(false);
    },
    [setIsSaving, setData, setErrors, definitionId]
  );

  useEffect(() => {
    loadDefinition(definitionId);
  }, [definitionId]);

  const disable = useCallback(() => saveDefinition({ is_active: false }), [saveDefinition]);
  const enable = useCallback(() => saveDefinition({ is_active: true }), [saveDefinition]);
  const updateDefinition = (updates: Partial<DefinitionEdits>) =>
    data && setData({ ...data, ...updates });

  const saveFormChanges = () => {
    data && saveDefinition(pluck(data, ["notifications", "admin_notes"]));
  };

  const showDisableConfirm = useCallback(() => {
    Modal.confirm({
      title: "Are you sure disable this Definition?",
      content:
        "Disabling a definition will stop Helios monitoring this metric for all users. If you just wish to unsubscribe please use the Slack configuration section below.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: disable
    });
  }, [disable]);

  return (
    <Page isLoading={data === null} data={data}>
      {(definition: Definition) => (
        <>
          <PageHeader
            title={definition.title}
            subTitle={definition.description}
            onBack={() => history.push("/definitions")}
            extra={[
              definition.is_active ? (
                <Button
                  key="active-button"
                  onClick={showDisableConfirm}
                  loading={isSaving}
                  type="danger"
                >
                  Disable
                </Button>
              ) : (
                <Button key="active-button" onClick={enable} loading={isSaving} type="primary">
                  Enable
                </Button>
              )
            ]}
          >
            <Row type="flex">
              <Statistic
                title="Status"
                value={definition.is_active ? "Active" : "Disabled"}
                style={{
                  margin: "20px 32px 20px 0"
                }}
              />

              <Statistic
                title="Trigger"
                value={definition.definition_trigger}
                style={{
                  margin: "20px 32px 20px 0"
                }}
              />

              <Statistic
                title="Noisy"
                value={definition.is_noisy ? "Yes" : "No"}
                style={{
                  margin: "20px 32px 20px 0"
                }}
              />

              {definition.data_getter_type === "templated_sql" && (
                <Statistic
                  title="Metric Name"
                  value={metricName(definition)}
                  style={{
                    margin: "20px 32px 20px 0"
                  }}
                />
              )}

              {definition.data_getter_type === "templated_sql" &&
                cutsForDisplay(definition.data_getter_arguments.cuts).map(([name, value]) => (
                  <Statistic
                    key={name}
                    title={name}
                    value={value}
                    style={{ margin: "20px 32px" }}
                  />
                ))}
              {metaForDisplay(definition).map(([name, value]) => (
                <Statistic
                  key={name}
                  title={name}
                  value={
                    name === "source_table" ? value.split(".")[value.split(".").length - 1] : value
                  }
                  style={{
                    margin: "20px 32px"
                  }}
                />
              ))}
            </Row>
          </PageHeader>
          <Wrapper>
            <ResponsiveFullBleed>
              <DefinitionChart definitionId={definitionId} />
            </ResponsiveFullBleed>

            <Route
              path="/definitions/:definitionId/:tab?"
              render={({ match, history }) => (
                <Tabs
                  activeKey={match.params.tab || "config"}
                  onChange={key => history.push(`/definitions/${definitionId}/${key}`)}
                  animated={false}
                >
                  <Tabs.TabPane tab="Configuration" key="config">
                    <DefinitionSummary
                      definition={definition}
                      errors={errors}
                      updateDefinition={updateDefinition}
                      onSave={saveFormChanges}
                      isSaving={isSaving}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Jobs" key="jobs">
                    <RecentJobsList
                      title={`Jobs from defintion #${definitionId}`}
                      definitionId={definitionId}
                    />
                  </Tabs.TabPane>
                </Tabs>
              )}
            />
          </Wrapper>
        </>
      )}
    </Page>
  );
};

export default DefinitionPage;
