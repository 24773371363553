import React from "react";
import { Select } from "antd";

const NOTIFICATION_STATUSES = [
  { label: "All Alerts", value: "" },
  { label: "Notified", value: "true" },
  { label: "Not Notified", value: "false" }
];

export type NotificationStatusValue = "" | "true" | "false";

interface NotificationSelectProps {
  value: NotificationStatusValue;
  onChange: (value: NotificationStatusValue) => void;
}

const NotificiationSelect: React.FC<NotificationSelectProps> = ({ value, onChange }) => (
  <Select style={{ width: 130 }} onChange={onChange} value={value}>
    {NOTIFICATION_STATUSES.map(({ label, value }) => (
      <Select.Option key={label} value={value}>
        {label}
      </Select.Option>
    ))}
  </Select>
);

export default NotificiationSelect;
