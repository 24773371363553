import React, { useMemo } from "react";

import { Definitions, Definition, SlackNotification } from "../types/api";
import { useHistory } from "react-router-dom";
import BaseTable, { Column } from "react-base-table";
import Text from "react-texty";
import { metaForDisplay, cutsForDisplay, metricName } from "../lib/definition-utils";
import "react-base-table/styles.css";
import "react-texty/styles.css";
import styled from "styled-components";
import DimensionPill from "./DimensionPill";
import { RelativeTime } from "./RelativeTime";
import { navigateTo } from "../lib/navigation";

const OnState = styled("div")<{ anomalous?: boolean | undefined | null }>`
  overflow: hidden;
  font-size: 1px;
  line-height: 1;
  width: 10px;
  height: 10px;
  border-radius: 3px;
  background-color: ${({ anomalous }) =>
    anomalous === true ? "red" : anomalous === false ? "green" : "yellow"};
`;

const OffState = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 3px;
  background-color: #ccc;
`;

const TableCell: React.FC<{
  className: string;
  cellData: any;
  rowData: Definition;
  column: any;
}> = ({ className, cellData, rowData, column }) => {
  if (column.key === "is_active") {
    return cellData ? (
      <OnState
        anomalous={rowData.last_processed_job ? rowData.last_processed_job.anomalous : undefined}
      />
    ) : (
      <OffState />
    );
  }
  if (column.key === "metric_name") {
    return <>{metricName(rowData)}</>;
  }
  if (column.key === "grain") {
    return <>{rowData.definition_trigger}</>;
  }
  if (column.key === "slack_channel") {
    const slackChannels = rowData.notifications.filter(
      n => n.notification_type === "slack"
    ) as SlackNotification[];
    if (slackChannels.length === 0) return <></>;
    if (slackChannels.length === 1) return <>{slackChannels[0].slack_channel}</>;
    return (
      <>
        {slackChannels[0].slack_channel} (+{slackChannels.length - 1})
      </>
    );
  }
  if (column.key === "last_job_processed_at") {
    return cellData ? <RelativeTime date={cellData} /> : <></>;
  }
  if (column.key === "cuts") {
    return (
      <>
        {cutsForDisplay(cellData).map(([name, value]) => (
          <DimensionPill key={name} name={name} value={value} />
        ))}
      </>
    );
  }
  return <Text className={className}>{cellData}</Text>;
};

const TableHeaderCell: React.FC<{ className: string; column: any }> = ({ className, column }) => (
  <Text className={className}>{column.title}</Text>
);

const DefinitionsTable: React.FC<{ definitions: Definitions }> = ({ definitions }) => {
  const history = useHistory();
  const eventHandlers = useMemo(
    () => ({
      onClick: ({ rowData, event }: { rowData: Definition; event: any }) =>
        navigateTo(history, `/definitions/${rowData.id}`)(event)
    }),
    [history]
  );
  const isTemplatedSQL = definitions[0].data_getter_type === "templated_sql";
  const meta = metaForDisplay(definitions[0]);
  return (
    <BaseTable
      data={definitions}
      width={1160}
      maxHeight={470}
      rowHeight={40}
      headerHeight={30}
      components={{ TableCell, TableHeaderCell }}
      rowEventHandlers={eventHandlers}
    >
      <Column key="is_active" dataKey="is_active" width={40} minWidth={40} />
      <Column key="title" dataKey="title" width={250} minWidth={250} title="Name" />
      <Column key="grain" dataKey="" width={100} minWidth={100} title="Trigger" />
      {isTemplatedSQL && (
        <Column
          key="metric_name"
          dataKey="data_getter_arguments.metric_name"
          width={180}
          title="Metric"
        />
      )}
      {isTemplatedSQL && (
        <Column key="cuts" dataKey="data_getter_arguments.cuts" title="Cuts" width={1160 - 840} />
      )}
      {meta.map(([name, _]) => (
        <Column
          key={name}
          dataKey={`data_getter_arguments.meta.${name}`}
          width={Math.floor(1160 - 700 / meta.length)}
          title={name}
        />
      ))}
      <Column
        key="slack_channel"
        dataKey="notifications"
        width={120}
        minWidth={120}
        title="Slack Channel"
      />
      <Column
        key="last_job_processed_at"
        dataKey="last_job_processed_at"
        width={150}
        minWidth={150}
        title="Last Processed"
      />
    </BaseTable>
  );
};

export default DefinitionsTable;
