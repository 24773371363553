import React from "react";
import styled from "styled-components/macro";
import Toggle from "react-toggle";

interface SelectOption {
  label: string;
  value: any;
}

export type SelectOptions = SelectOption[];

interface FieldProps {
  readonly id: string;
  readonly label: string;
  readonly type?: "text" | "select" | "textarea" | "checkbox" | "toggle";
  readonly options?: SelectOptions;
  readonly value?: string | boolean;
  readonly autoComplete?: string;
  readonly onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void;
  readonly errors?: string[];
  readonly disabled?: boolean;
  readonly hint?: string;
}

interface AdditionalInputProps {
  hasErrors?: boolean;
  disabled?: boolean;
}

const Label = styled.label<{ disabled?: boolean }>`
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  padding-bottom: 10px;
  color: ${({ disabled }) => (disabled ? "#ccc" : "inherit")};
`;

const Hint = styled.div`
  font-size: 12px;
  padding: 10px 0 0 0;
`;

const Input = styled.input<AdditionalInputProps>`
  display: block;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  background: #fbfbfb;
  font-family: inherit;
  font-size: 18px;
  padding: 10px;
  width: 100%;
  border-color: ${({ hasErrors }) => (hasErrors ? "#e02020" : "#dbdbdb")};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

const Textarea = styled.textarea<AdditionalInputProps>`
  display: block;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  background: #fbfbfb;
  font-family: inherit;
  font-size: 18px;
  padding: 10px;
  width: 100%;
  height: 6em;
  border-color: ${({ hasErrors }) => (hasErrors ? "#e02020" : "#dbdbdb")};
`;

const FieldWrapper = styled.div`
  padding-bottom: 20px;
`;

export const FormSection = styled.div`
  margin-bottom: 60px;
`;

export const FormSectionBody = styled.div`
  padding-top: 30px;
`;

const SelectInput = styled.select<AdditionalInputProps>`
  display: block;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  font-family: inherit;
  font-size: 18px;
  padding: 10px 30px 10px 10px;
  width: 100;
  max-width: 100%;
  height: auto;
  appearance: none;
  background-color: #fbfbfb;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 14px auto;
  border-color: ${({ hasErrors }) => (hasErrors ? "#e02020" : "#dbdbdb")};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

const Errors = styled.div`
  color: #e02020;
  font-size: 14px;
  padding: 10px 0 0 0;
`;

const Select: React.FC<
  {
    id: string;
    options: SelectOptions;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  } & AdditionalInputProps
> = ({ id, options, ...props }) => (
  <SelectInput id={id} {...props}>
    {options.map(({ label, value }) => (
      <option key={value} value={value}>
        {label}
      </option>
    ))}
  </SelectInput>
);

export const Field: React.FC<FieldProps> = ({
  label,
  id,
  type = "text",
  options = [],
  value,
  onChange,
  autoComplete,
  errors,
  hint = undefined,
  disabled = false
}) => {
  let input = (
    <Input
      id={id}
      type={type}
      value={value as any}
      onChange={onChange}
      autoComplete={autoComplete}
      hasErrors={errors && errors.length > 0}
      disabled={disabled}
    />
  );
  if (type === "toggle") input = <Toggle checked={value as boolean} onChange={onChange} />;
  if (type === "select")
    input = (
      <Select
        id={id}
        options={options}
        hasErrors={errors && errors.length > 0}
        disabled={disabled}
        onChange={onChange}
      />
    );
  if (type === "textarea")
    input = (
      <Textarea
        id={id}
        value={value as string}
        onChange={onChange}
        hasErrors={errors && errors.length > 0}
        disabled={disabled}
      />
    );
  return (
    <FieldWrapper>
      <Label htmlFor={id} disabled={disabled}>
        {label}
      </Label>
      {input}
      {hint && <Hint>{hint}</Hint>}
      {errors && <Errors>{errors.join(", ")}</Errors>}
    </FieldWrapper>
  );
};
