import React from "react";

// export const relativeTime = (datetimeString: Date | string | null): string => {
//   if (datetimeString == null) return "unknown";
//   let date = typeof datetimeString === "string" ? new Date(datetimeString) : datetimeString;
//   return formatter.format(date);
// };

export const dateTimeFormat = (datetimeString: string | null, short: boolean = false) => {
  if (datetimeString == null) return "";
  const date = new Date(datetimeString);
  if (short && date.toLocaleDateString() === new Date().toLocaleDateString()) {
    return date.toLocaleTimeString();
  }
  return (
    <div>
      {date.toLocaleDateString()} <span style={{ color: "#aaa" }}>@</span>{" "}
      {date.toLocaleTimeString()}
    </div>
  );
};
