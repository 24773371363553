import ReactGA from "react-ga";
import { Location as HLocation } from "history";
import { GoogleLoginResponse } from "react-google-login";
import { GA_TRACKING_ID } from "./config";

export const initAnalytics = (env: "production" | "development" | "test") =>
  ReactGA.initialize(GA_TRACKING_ID, {
    debug: env === "development",
    testMode: env === "test",
    titleCase: false,
    gaOptions: {
      siteSpeedSampleRate: 100
    }
  });

export const trackPageview = (location: Location | HLocation<any>) => {
  const page = location.pathname + location.search;
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

export const trackUser = ({ googleId, email }: GoogleLoginResponse["profileObj"]) => {
  ReactGA.set({ googleId, email });
};

export const trackFeedbackEvent = (action: "quick" | "full", usefulness: string, jobId: number) =>
  ReactGA.event({
    category: "feedback",
    action: action,
    label: usefulness,
    value: jobId
  });
