import React from "react";
import { Badge, Select } from "antd";
import { AlertStatus } from "../types/api";

const ALERT_STATUSES: AlertStatus[] = ["initialised", "investigating", "resolved", "ignored"];

export const ALL_ALERT_STATUS = "all";
export const ALL_OPEN_ALERT_STATUS = "initialised,investigating";

export type AlertStatusOption =
  | AlertStatus
  | typeof ALL_ALERT_STATUS
  | typeof ALL_OPEN_ALERT_STATUS;

const ALERT_COLORS: { [key in AlertStatus]: string } = {
  initialised: "yellow",
  investigating: "pink",
  resolved: "green",
  ignored: "grey"
};

interface AlertStatusSelectProps {
  value: AlertStatusOption | undefined;
  onChange: (value: AlertStatusOption) => void;
  includeAllOption?: boolean;
  includeAllOpen?: boolean;
}

const AlertStatusSelect: React.FC<AlertStatusSelectProps> = ({
  value,
  onChange,
  includeAllOption = false,
  includeAllOpen = false
}) => (
  <Select style={{ width: 130 }} onChange={onChange} defaultValue={value}>
    {includeAllOption && (
      <Select.Option key="all" value={ALL_ALERT_STATUS}>
        <Badge color="blue" text="All Alerts" />
      </Select.Option>
    )}
    {includeAllOpen && (
      <Select.Option key="all-open" value={ALL_OPEN_ALERT_STATUS}>
        <Badge color="purple" text="Open Alerts" />
      </Select.Option>
    )}
    {ALERT_STATUSES.map(status => (
      <Select.Option key={status} value={status}>
        <Badge color={ALERT_COLORS[status]} text={status} />
      </Select.Option>
    ))}
  </Select>
);

export default AlertStatusSelect;
