import React, { useState, useMemo, useCallback, useEffect } from "react";
import useFetch from "react-fetch-hook";

import { ListDefinitionsResponse, Definitions } from "../types/api";

import Page, { Wrapper } from "../components/Page";
import DefinitionList from "../components/DefinitionList";
import { definitionsUrl } from "../lib/api";
import styled from "styled-components";
import BulkEditModal from "./BulkEditModal";
import { PageHeader, Button, Form, Input } from "antd";
import { Link } from "react-router-dom";

let DEFINITIONS_CACHE: Definitions | undefined = undefined;
let SEARCH_TERM_CACHE: string = "";

const filter = (definitions: Definitions, searchTerm: string, showDisabled: boolean) => {
  let terms = searchTerm.toLowerCase().split(" ");
  if (showDisabled === false) {
    definitions = definitions.filter(d => d.is_active);
  }
  return definitions.filter(d => {
    const searchDoc = JSON.stringify(d).toLowerCase();
    return terms.every(term => searchDoc.includes(term));
  });
};

const NoResults = styled.div`
  padding: 100px 0;
  text-align: center;
  font-size: 24px;
  color: #999;
`;

const SearchBar = styled.div`
  text-align: right;
`;

const DefinitionsPage: React.FC = () => {
  const { data: response } = useFetch<ListDefinitionsResponse>(definitionsUrl());
  useEffect(() => {
    if (response) DEFINITIONS_CACHE = response.data;
  }, [response]);
  const definitions = response ? response.data : DEFINITIONS_CACHE;
  const [searchTerm, setSearchTerm] = useState(SEARCH_TERM_CACHE);
  const [isBulkEditing, setIsBulkEditing] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);

  const updateSearch = useCallback(
    e => {
      const newTerm = e.target.value;
      setSearchTerm(newTerm);
      SEARCH_TERM_CACHE = newTerm;
    },
    [setSearchTerm]
  );

  const filteredDefinitions = useMemo(
    () => (definitions ? filter(definitions, searchTerm, showDisabled) : []),
    [definitions, searchTerm, showDisabled]
  );

  const toggleBulkEditing = useCallback(() => setIsBulkEditing(!isBulkEditing), [
    isBulkEditing,
    setIsBulkEditing
  ]);

  return (
    <Page isLoading={!definitions} data={definitions}>
      <PageHeader
        title="Definitions"
        subTitle="All Active Job Definitions"
        extra={[
          <Button key="add" type="primary">
            <Link to="/definitions/new">Add Definitions</Link>
          </Button>
        ]}
      >
        <SearchBar>
          <Form layout="inline">
            <Form.Item>
              <Button
                disabled={searchTerm === "" || filteredDefinitions.length === 0}
                onClick={toggleBulkEditing}
                size="large"
              >
                Bulk Edit
              </Button>
            </Form.Item>
            <Form.Item>
              <Button onClick={() => setShowDisabled(!showDisabled)} size="large">
                {showDisabled ? "Hide" : "Show"} Disabled
              </Button>
            </Form.Item>
            <Form.Item style={{ marginRight: 0 }}>
              <Input.Search
                placeholder="Filter Definitions"
                value={searchTerm}
                onChange={updateSearch}
                size="large"
                allowClear
              />
            </Form.Item>
          </Form>
        </SearchBar>
      </PageHeader>

      <Wrapper>
        {filteredDefinitions.length === 0 ? (
          <NoResults>No Results</NoResults>
        ) : (
          <DefinitionList definitions={filteredDefinitions} />
        )}

        <BulkEditModal
          visible={isBulkEditing}
          definitions={filteredDefinitions}
          onCancel={toggleBulkEditing}
        />
      </Wrapper>
    </Page>
  );
};

export default DefinitionsPage;
