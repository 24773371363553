import { GoogleLoginResponse } from "react-google-login";

const KEY = "google-auth-token";
const TOKEN_REFRESH_RATE = 1000 * 60 * 10;

export const setToken = (token: string) => window.localStorage.setItem(KEY, token);
export const getToken = () => window.localStorage.getItem(KEY);

export type Profile = GoogleLoginResponse["profileObj"];
let profile: Profile | null = null;

export const getProfile = () => profile;
export const setProfile = (p: Profile): Profile => {
  profile = p;
  return profile;
};

const setTokenWithReschedule = (login: GoogleLoginResponse, token: string) => {
  setToken(token);
  setProfile(login.profileObj);

  setTimeout(async () => {
    const authReponse = await login.reloadAuthResponse();
    setTokenWithReschedule(login, authReponse.id_token);
  }, TOKEN_REFRESH_RATE);
};

export const setTokenWithRefresh = (login: GoogleLoginResponse) => {
  setTokenWithReschedule(login, login.getAuthResponse().id_token);
};

export const setTokenFromQuery = () => {
  const url = new URL(window.location.href);
  const token = url.searchParams.get("t");
  if (token) setToken(token);
  return token;
};
