import useFetch from "react-fetch-hook";
import {
  GetTimeseriesResponse,
  Job,
  GetJobResponse,
  JobsAPIParams,
  ListJobsResponse,
  UsersAPIParams,
  ListUsersResponse,
  GetAlertResponse
} from "../types/api";
import { timeseriesUrl, jobUrl, jobsUrl, usersUrl, alertUrl } from "../lib/api";

export const useTimeseries = (job: string | number | Job | null | undefined) => {
  const jobId = getJobId(job);
  const { data, isLoading } = useFetch<GetTimeseriesResponse>(timeseriesUrl(jobId as number), {
    depends: [jobId]
  });
  const timeseries = data ? data.data : null;
  return { timeseries, isLoadingTimeseries: isLoading };
};

export const useJob = (jobId: number | string | null | undefined) => {
  const { data, isLoading, error } = useFetch<GetJobResponse>(jobUrl(jobId as number), {
    depends: [jobId]
  });
  const job = data ? data.data : null;
  return { response: data, error, job, isLoadingJob: isLoading };
};

export const useJobsList = (params: JobsAPIParams = {}) => {
  const page = params.page || 1;
  const { data, isLoading, error } = useFetch<ListJobsResponse>(jobsUrl(params), {
    depends: [page]
  });
  const jobs = data ? data.data : [];
  return {
    error,
    jobs,
    isLoadingJobsList: isLoading,
    totalCount: data ? data.total_count : 0,
    page
  };
};

export const useUsersList = (params?: UsersAPIParams) => {
  const { data, isLoading, error } = useFetch<ListUsersResponse>(usersUrl(params));
  const users = data ? data.data : [];
  return {
    error,
    users,
    isLoadingUsersList: isLoading,
    totalCount: data ? data.total_count : 0
  };
};

export const useAlert = (alertId: number | string) => {
  const { data, isLoading, error } = useFetch<GetAlertResponse>(alertUrl(alertId as number), {
    depends: [alertId]
  });
  const alert = data ? data.data : null;
  return { response: data, error, alert, isLoadingAlert: isLoading };
};

const getJobId = (job: string | number | Job | null | undefined) => {
  let jobId = null;
  if (typeof job === "number") jobId = job;
  if (typeof job === "string") jobId = job;
  if (job && typeof job !== "number" && typeof job !== "string" && job.id !== undefined)
    jobId = job.id;
  return jobId;
};
