import React from "react";
import styled from "styled-components";

const Code = styled.code`
  display: block;
  background-color: #f6f6f6;
  margin: 20px 0 40px 0;
  padding: 10px;
  border-radius: 4px;
  line-height: 1.6;
  overflow: auto;
  max-height: 500px;
`;

export const QueryCode: React.FC = ({ children }) => (
  <Code>
    <pre>{children}</pre>
  </Code>
);

export default Code;
