import React, { useState, useCallback } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { GoogleLoginResponse } from "react-google-login";
import { createBrowserHistory } from "history";

import { Layout } from "antd";

import "antd/dist/antd.css";

import SiteHeader from "./components/SiteHeader";
import DefinitionsPage from "./containers/DefinitionsPage";
import AlertPage from "./containers/AlertPage";
import AlertsPage from "./containers/AlertsPage";
import TriagePage from "./containers/TriagePage";
import NotFoundPage from "./containers/NotFoundPage";
import LoginPage from "./containers/LoginPage";
import DefinitionPage from "./containers/DefinitionPage";
import DefinitionBuilderPage from "./containers/DefinitionBuilderPage";
import { setTokenWithRefresh } from "./lib/token";
import JobPage from "./containers/JobPage";
import JobsPage from "./containers/JobsPage";
import JobChart from "./containers/JobChart";
import { initAnalytics, trackPageview, trackUser } from "./lib/ga";
import { DomainWarning } from "./components/DomainWarning";

type SessionState = null | GoogleLoginResponse;

interface AppProps {
  readonly onLogout?: () => void;
}

initAnalytics(process.env.NODE_ENV);

const ChartApp: React.FC = () => (
  <Switch>
    <Route path="/charts/job/:jobId" component={JobChart} />
  </Switch>
);

const AuthenticatedApp: React.FC = () => {
  const [session, setSession] = useState<SessionState>(null);
  const logout = useCallback(() => setSession(null), [setSession]);
  const login = useCallback(
    (s: SessionState) => {
      setSession(s);
      if (s) {
        setTokenWithRefresh(s);
        trackUser(s.profileObj);
      }
    },
    [setSession]
  );
  if (session === null && !window.location.pathname.startsWith("/_"))
    return <LoginPage onLogin={login} />;
  return <App onLogout={logout} />;
};

const App: React.FC<AppProps> = ({ onLogout = () => null }) => {
  return (
    <Layout>
      <SiteHeader onLogout={onLogout} />
      <DomainWarning />

      <Layout.Content style={{ backgroundColor: "#fff" }}>
        <Switch>
          <Redirect exact from="/" to="/alerts" />
          <Route path="/jobs/:jobId" component={JobPage} />
          <Route path="/jobs" component={JobsPage} />
          <Route path="/alerts/:alertId" component={AlertPage} />
          <Route path="/alerts" component={AlertsPage} />
          <Route path="/definitions/new" component={DefinitionBuilderPage} />
          <Route path="/definitions/:definitionId" component={DefinitionPage} />
          <Route path="/definitions" component={DefinitionsPage} />
          <Route path="/triage" component={TriagePage} />
          <Route component={NotFoundPage} />
        </Switch>
      </Layout.Content>
    </Layout>
  );
};

export const BrowserApp: React.FC = () => {
  const history = createBrowserHistory();
  // track the initial pageview
  trackPageview(window.location);
  // track location changes
  history.listen(trackPageview);

  return (
    <Router history={history}>
      <Switch>
        <Route path="/charts" component={ChartApp} />
        <Route component={AuthenticatedApp} />
      </Switch>
    </Router>
  );
};

export default App;
