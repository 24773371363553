import React from "react";
import ReactTimeAgo from "react-time-ago";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

JavascriptTimeAgo.locale(en);

interface RelativeTimeProps {
  date: Date | string | null | undefined;
}

export const RelativeTime: React.FC<RelativeTimeProps> = ({ date }) => {
  if (date == null) return <>unknown</>;
  const dateValue = typeof date === "string" ? new Date(date) : date;
  return <ReactTimeAgo date={dateValue} timeStyle="twitter" />;
};
