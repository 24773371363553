export interface UsefulnessMap {
  not_at_all: string;
  somewhat: string;
  very: string;
}

export const FEEDBACK_USEFULNESS: UsefulnessMap = {
  not_at_all: "Not At All Useful",
  somewhat: "Somewhat Useful",
  very: "Very Useful"
};
