import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";

export const ButtonLink = styled(Link)<{ background?: string }>`
  display: inline-block;
  color: #fff;
  background-color: ${({ background = "#0091ff" }) => background};
  text-decoration: none;
  padding: 14px 20px;
  border-radius: 4px;
  &:hover {
    background-color: #0079d5;
  }
  & + a,
  & + button {
    margin-left: 10px;
  }
`;

const Btn = styled.button`
  position: relative;
  display: inline-block;
  color: #fff;
  background-color: #0091ff;
  border: none;
  text-decoration: none;
  padding: 14px 20px;
  border-radius: 4px;
  font-family: inherit;
  font-size: inherit;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #0079d5;
  }
  &:disabled,
  &:disabled:hover {
    background-color: #ddd;
  }
  & + a,
  & + button {
    margin-left: 10px;
  }
`;

const BtnContent = styled.div<{ isLoading: boolean }>`
  visibility: ${({ isLoading }) => (isLoading ? "hidden" : "")};
`;

const BtnLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  & > div {
    margin: 9px auto;
  }
`;

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & { isLoading?: boolean }> = ({
  children,
  isLoading = false,
  onClick,
  disabled = false
}) => (
  <Btn onClick={onClick} disabled={disabled}>
    <BtnLoader>{isLoading && <LoadingSpinner size={40} color="rgba(255,255,255,0.8)" />}</BtnLoader>
    <BtnContent isLoading={isLoading}>{children}</BtnContent>
  </Btn>
);

export default Button;
