import React, { useState, useCallback } from "react";
import { Definitions, Definition, DefinitionEdits } from "../types/api";
import { Field } from "../components/Form";
import { patch, definitionUrl } from "../lib/api";
import { formatNumber, rejectNulls, union } from "../lib/utils";
import Grid from "../components/Grid";
import styled from "styled-components";
import { Modal, Button, Switch, Progress } from "antd";
import {
  slackNotificationsFromStringList,
  slackNotificationsToStringList
} from "../lib/notifications";

interface BulkEditModalProps {
  definitions: Definitions;
  onCancel: () => void;
  visible: boolean;
}

interface Report {
  success: number;
  failed: number;
  total: number;
}

const EditableInputWrapper = styled.div`
  text-align: center;
  padding-top: 40px;
`;

const saveDefinition = async (id: number, updates: Partial<Definition>): Promise<Boolean> => {
  const result = await patch<Definition>(definitionUrl(id), rejectNulls(updates));
  return result.status === "success";
};

const saveAll = async (
  setIsSaving: any,
  definitions: Definitions,
  updates: Partial<Definition>,
  onUpdate: (r: Report) => void,
  onComplete: (r: Report) => void
) => {
  setIsSaving(true);
  let report: Report = { success: 0, failed: 0, total: definitions.length };
  await definitions.reduce(async (previous, definition) => {
    await previous;
    const updatesWithNotifications = updates.notifications
      ? { ...updates, notifications: union(definition.notifications, updates.notifications) }
      : updates;
    const result = await saveDefinition(definition.id, updatesWithNotifications);
    if (result) {
      report = { ...report, success: report.success + 1 };
    } else {
      report = { ...report, failed: report.failed + 1 };
    }
    onUpdate(report);
  }, Promise.resolve());
  setIsSaving(false);
  onComplete(report);
};

const ProgressView = styled.div`
  text-align: center;
  padding: 20px;
`;

const ProgressLabel = styled.div`
  padding-top: 20px;
  font-size: 1.1rem;
`;

const BulkEditModal: React.FC<BulkEditModalProps> = ({ definitions, onCancel, visible }) => {
  const [updates, setUpdates] = useState<Partial<Definition>>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [report, setReport] = useState<Report>({
    success: 0,
    failed: 0,
    total: definitions.length
  });

  const update = (changes: Partial<DefinitionEdits>) => setUpdates({ ...updates, ...changes });

  const save = useCallback(
    () => saveAll(setIsSaving, definitions, updates, setReport, r => setIsDone(true)),
    [definitions, setIsSaving, updates, setReport, setIsDone]
  );

  const onDone = useCallback(() => window.location.reload(), []);

  return (
    <Modal
      visible={visible}
      title={`Bulk Editing ${formatNumber(definitions.length)} Definitions`}
      onCancel={onCancel}
      onOk={save}
      footer={
        isDone
          ? [
              <Button key="done" onClick={onDone}>
                Done
              </Button>
            ]
          : [
              <Button key="cancel" onClick={onCancel}>
                Cancel
              </Button>,
              <Button
                key="save"
                type="primary"
                loading={isSaving}
                onClick={save}
                disabled={isSaving || Object.keys(rejectNulls(updates)).length === 0}
              >
                Update {formatNumber(definitions.length)} Definitions
              </Button>
            ]
      }
    >
      {isSaving || isDone ? (
        <ProgressView>
          <Progress
            type="circle"
            percent={Math.round(((report.success + report.failed) / report.total) * 100)}
            successPercent={Math.round((report.success / report.total) * 100)}
          />
          <ProgressLabel>
            Updated {report.success} of {report.total}{" "}
            {report.failed > 0 && <span>({report.failed} Failed)</span>}
          </ProgressLabel>
        </ProgressView>
      ) : (
        <>
          <Grid columns="1fr 11fr">
            <EditableInputWrapper>
              <Switch
                checked={updates.is_active !== undefined}
                onChange={checked => update({ is_active: checked ? true : undefined })}
              />
            </EditableInputWrapper>
            <Field
              id="is_active"
              label="State"
              type="select"
              options={[
                { label: "Enabled", value: "1" },
                { label: "Disabled", value: "0" }
              ]}
              value={updates.is_active ? "1" : "0"}
              onChange={e => update({ is_active: e.target.value === "1" })}
              disabled={updates.is_active === undefined}
            />
          </Grid>
          <Grid columns="1fr 11fr">
            <EditableInputWrapper>
              <Switch
                checked={updates.notifications !== undefined}
                onChange={checked => update({ notifications: checked ? [] : undefined })}
              />
            </EditableInputWrapper>
            <Field
              id="slack_channel"
              label="Add Slack Channel"
              value={
                updates.notifications
                  ? slackNotificationsToStringList(updates.notifications).join(" ")
                  : ""
              }
              onChange={e =>
                update({
                  notifications: slackNotificationsFromStringList(e.target.value.split(" "))
                })
              }
              disabled={updates.notifications === undefined}
            />
          </Grid>
        </>
      )}
    </Modal>
  );
};

export default BulkEditModal;
