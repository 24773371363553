import React from "react";
import styled from "styled-components/macro";
import LoadingSpinner from "./LoadingSpinner";
import { useMediaQuery } from "beautiful-react-hooks";

const PageDiv = styled.div`
  position: relative;
  margin: 20px auto;
  max-width: 1200px;
`;

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 32px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
`;

export const Extra = styled.div``;

export const Subtitle = styled.p`
  display: block;
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 18px;
  font-weight: 500;
`;

type PageProps = {
  isLoading?: boolean;
  data?: any;
};

export const Wrapper = styled.div`
  padding: 0 20px;
`;

export const FullBleed = styled.div`
  position: relative;
  margin: 0 -20px;
`;

export const ResponsiveFullBleed: React.FC<{ width?: number }> = ({ children, width = 720 }) => {
  const isWide = useMediaQuery(`(min-width: ${width}px)`);
  if (isWide) return <>{children}</>;
  return <FullBleed>{children}</FullBleed>;
};

const Page: React.FC<PageProps> = ({ isLoading = false, children, data }) =>
  isLoading ? (
    <LoadingSpinner data-testid="page-loading-spinner" />
  ) : (
    <PageDiv>{typeof children === "function" ? data && children(data) : children}</PageDiv>
  );

export default Page;
