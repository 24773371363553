import React from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import TimeseriesChart from "../components/TimeseriesChart";
import { metricName, normaliseGrain } from "../lib/definition-utils";
import { useTimeseries, useJob } from "../hooks/api";

const InlineJobRow: React.FC<{ jobId: number }> = ({ jobId }) => {
  const { job, isLoadingJob } = useJob(jobId);
  const { timeseries, isLoadingTimeseries } = useTimeseries(jobId);

  if (isLoadingJob || isLoadingTimeseries) return <LoadingSpinner />;
  if (job && timeseries) {
    return (
      <TimeseriesChart
        timeseries={timeseries}
        grain={normaliseGrain(job.definition)}
        metricName={metricName(job.definition)}
        width={1094}
      />
    );
  } else {
    return (
      <div>
        <strong>No data</strong> - Maybe this job is still running?
      </div>
    );
  }
};

export default InlineJobRow;
