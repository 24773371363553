import React, { ReactElement, useState } from "react";

import Page, { Wrapper, ResponsiveFullBleed } from "../components/Page";
import TimeseriesChart from "../components/TimeseriesChart";
import { useParams, Route } from "react-router";
import { PageHeader, Button, Tag, Row, Statistic, Tooltip, Tabs, Badge } from "antd";
import { Link } from "react-router-dom";
import RecentJobsList from "./RecentJobsList";
import ErrorView from "../components/ErrorView";
import JobFeedbackForm from "./JobFeedbackForm";
import FeedbackList from "../components/FeedbackList";
import { destroy, feedbackUrl } from "../lib/api";
import { metricName, normaliseGrain, cutsForDisplay } from "../lib/definition-utils";
import { useMediaQuery } from "beautiful-react-hooks";
import { useTimeseries, useJob } from "../hooks/api";
import { Job } from "../types/api";

const tagsForJob = (job: Job) => {
  let tags: ReactElement<Tag>[] = [];
  if (job.completed_at == null) return tags;
  tags.push(
    <Tag key="completed" color="blue">
      Completed
    </Tag>
  );
  if (job.anomalous)
    tags.push(
      <Tag key="anomalous" color="volcano">
        Anomalous
      </Tag>
    );
  return tags;
};

const JobPage: React.FC = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const [deletedFeedbackIds, setDeletedFeedbackIds] = useState<number[]>([]);
  const { error, job } = useJob(jobId);
  const { timeseries } = useTimeseries(job);

  const isDesktop = useMediaQuery("(min-width: 720px)");

  const deleteFeedback = async (feedbackId: number) => {
    const response = await destroy(feedbackUrl(feedbackId));
    if (response.status === "success") {
      setDeletedFeedbackIds(deletedFeedbackIds.concat([feedbackId]));
    }
  };

  if (error) return <ErrorView error={error} />;
  return (
    <Page data={job} isLoading={job == null}>
      {(job: Job) => (
        <>
          <PageHeader
            title={`Job #${job.id}`}
            subTitle={job.definition.title}
            extra={[
              <JobFeedbackForm job={job} key="feedback" />,
              <Button key="view-definition">
                <Tooltip
                  placement="bottom"
                  title={`${job.definition.title}\n${job.definition.description}`}
                >
                  <Link to={`/definitions/${job.definition.id}`}>View Definition</Link>
                </Tooltip>
              </Button>
            ]}
            tags={tagsForJob(job)}
          >
            <Row type={isDesktop ? "flex" : undefined}>
              {job.completed_at && (
                <Statistic
                  title="Completed"
                  value={new Date(job.completed_at).toLocaleTimeString()}
                  suffix={new Date(job.completed_at).toLocaleDateString()}
                  valueStyle={{ fontSize: isDesktop ? "22px" : "18px" }}
                  style={{
                    margin: isDesktop ? "20px 32px 20px 0" : "0 0 10px 0"
                  }}
                />
              )}
              {job.direction && (
                <Statistic
                  title="Direction"
                  value={job.direction}
                  valueStyle={{ fontSize: isDesktop ? "22px" : "18px" }}
                  style={{
                    margin: isDesktop ? "20px 32px 20px 0" : "0 0 10px 0"
                  }}
                />
              )}
              {job.confidence && (
                <Statistic
                  title="Confidence"
                  value={job.confidence}
                  valueStyle={{ fontSize: isDesktop ? "22px" : "18px" }}
                  style={{
                    margin: isDesktop ? "20px 32px 20px 0" : "0 0 10px 0"
                  }}
                />
              )}
              <Statistic
                title="Trigger"
                value={job.definition.definition_trigger}
                valueStyle={{ fontSize: isDesktop ? "22px" : "18px" }}
                style={{
                  margin: isDesktop ? "20px 32px 20px 0" : "0 0 10px 0"
                }}
              />
              {job.definition.data_getter_type === "templated_sql" && (
                <Statistic
                  title="Metric Name"
                  value={metricName(job.definition)}
                  valueStyle={{ fontSize: isDesktop ? "22px" : "18px" }}
                  style={{
                    margin: isDesktop ? "20px 32px 20px 0" : "0 0 10px 0"
                  }}
                />
              )}

              {job.definition.data_getter_type === "templated_sql" &&
                cutsForDisplay(job.definition.data_getter_arguments.cuts).map(([name, value]) => (
                  <Statistic
                    key={name}
                    title={name}
                    value={value}
                    valueStyle={{ fontSize: isDesktop ? "22px" : "18px" }}
                    style={{
                      margin: isDesktop ? "20px 32px" : "0 0 10px 0"
                    }}
                  />
                ))}
            </Row>
          </PageHeader>

          <Wrapper>
            <ResponsiveFullBleed>
              {timeseries && (
                <TimeseriesChart
                  timeseries={timeseries}
                  grain={normaliseGrain(job.definition)}
                  metricName={metricName(job.definition)}
                />
              )}
            </ResponsiveFullBleed>

            <Route
              path="/jobs/:jobId/:tab?"
              render={({ match, history }) => (
                <Tabs
                  activeKey={match.params.tab || "related"}
                  onChange={key => history.push(`/jobs/${job.id}/${key}`)}
                  animated={false}
                >
                  <Tabs.TabPane tab="Related Jobs" key="related">
                    <RecentJobsList
                      title={`Latest Jobs from Definition #${job.definition.id}`}
                      definitionId={job.definition.id}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <span>
                        Feedback
                        <Badge count={job.feedbacks.length} style={{ marginLeft: "1em" }} />
                      </span>
                    }
                    key="feedback"
                  >
                    <FeedbackList
                      feedbacks={job.feedbacks.filter(f => !deletedFeedbackIds.includes(f.id))}
                      onDelete={deleteFeedback}
                    />
                  </Tabs.TabPane>
                </Tabs>
              )}
            />
          </Wrapper>
        </>
      )}
    </Page>
  );
};

export default JobPage;
