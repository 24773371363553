import React from "react";

import Page, { Wrapper } from "../components/Page";
import RecentJobsList from "./RecentJobsList";

const JobsPage: React.FC = () => {
  return (
    <Page>
      <Wrapper>
        <RecentJobsList defaultAnomalousOnly />
      </Wrapper>
    </Page>
  );
};

export default JobsPage;
