import { Definition, Dictionary, DataGetterPeriod } from "../types/api";

const EXCLUDED_META_KEYS = ["source_table", "metric", "day"];

export const metaForDisplay = (d: Definition): [string, string][] =>
  Object.entries(d.data_getter_arguments.meta)
    .filter(([name, value]) => value != null && !EXCLUDED_META_KEYS.includes(name))
    .map(([name, value]) => [name, (value || "-").toString()]);

const tidyCutName = (name: string | null) =>
  name ? name.replace(/^[cm]_/, "").replace(/_/g, " ") : "";

export const metricName = (d: Definition): string =>
  d.data_getter_type === "bigquery_sql"
    ? d.data_getter_arguments.meta.metric
    : tidyCutName(d.data_getter_arguments.metric_name);

export const cutsForDisplay = (cuts: Dictionary): [string, string][] =>
  Object.entries(cuts).map(([name, value]) => [tidyCutName(name), value]);

export const normaliseGrain = (d: Definition): DataGetterPeriod => {
  if (d.data_getter_type === "templated_sql") return d.data_getter_arguments.period;
  return d.definition_trigger === "hourly" ? "HOUR" : "DAY";
};

export const periodForDisplay = (period: DataGetterPeriod): string => {
  if (period === "HOUR") return "Hourly";
  if (period === "DAY") return "Daily";
  return "Unkown";
};
