import React, { useMemo } from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import DefinitionChart from "./DefinitionChart";
import { useAlert } from "../hooks/api";

const InlineAlertRow: React.FC<{ alertId: number }> = ({ alertId }) => {
  const { alert } = useAlert(alertId);

  const allJobs = useMemo(() => {
    if (alert == null) return [];
    const jobsList = [alert.primary_job, ...alert.sub_jobs];
    return jobsList.sort((a, b) => b.id - a.id);
  }, [alert]);

  if (alert === null) return <LoadingSpinner />;
  return (
    <DefinitionChart
      definitionId={alert.primary_job.definition.id}
      showOptions={false}
      filterAnomalousMarksByJobId={allJobs.map(j => j.id)}
    />
  );
};

export default InlineAlertRow;
