import React from "react";
import { Job } from "../types/api";
import LoadingSpinner from "./LoadingSpinner";
import { useHistory } from "react-router";
import { Table, Tag, Popover, Descriptions } from "antd";
import JobFeedbackForm from "../containers/JobFeedbackForm";
import { PaginationConfig } from "antd/lib/table";
import { metricName, cutsForDisplay, metaForDisplay } from "../lib/definition-utils";
import DimensionPill from "../components/DimensionPill";
import InlineJobRow from "../containers/InlineJobRow";
import { dateTimeFormat } from "../lib/datetime";
import { navigateTo } from "../lib/navigation";
import { useMediaQuery } from "beautiful-react-hooks";

export const timingInfo = (job: Job) => {
  let runtime: null | number = null;
  if (job.completed_at) {
    runtime = new Date(job.completed_at).getTime() - new Date(job.created_at).getTime();
  }
  return (
    <Descriptions column={1}>
      <Descriptions.Item label="Status">
        {job.completed_at ? "Completed" : "Running"}
      </Descriptions.Item>
      <Descriptions.Item label="Started">{dateTimeFormat(job.created_at)}</Descriptions.Item>
      {job.completed_at && (
        <Descriptions.Item label="Finished">{dateTimeFormat(job.completed_at)}</Descriptions.Item>
      )}
      {runtime && (
        <Descriptions.Item label="Runtime">{Math.round(runtime / 1000)} seconds</Descriptions.Item>
      )}
    </Descriptions>
  );
};

const idFormat = (id: string, job: Job) => {
  return (
    <Popover
      placement="topLeft"
      title="Timing Info"
      content={timingInfo(job)}
      overlayStyle={{ maxWidth: 300 }}
    >
      #<strong>{id}</strong>
    </Popover>
  );
};

const timeFormat = (at: string, job: Job) => {
  return (
    <Popover
      placement="topLeft"
      title="Timing Info"
      content={timingInfo(job)}
      overlayStyle={{ maxWidth: 300 }}
    >
      {dateTimeFormat(at, true)}
    </Popover>
  );
};

const definitionTitleFormat = (title: string, job: Job) => (
  <Popover
    placement="topLeft"
    title={title}
    content={<p>{job.definition.description}</p>}
    overlayStyle={{ maxWidth: 300 }}
  >
    {title}
  </Popover>
);

interface RecentJobsListProps {
  jobs: Job[] | null;
  pagination?: false | PaginationConfig;
}

const JobsList: React.FC<RecentJobsListProps> = ({ jobs, pagination = false as false }) => {
  const history = useHistory();
  const isDesktop = useMediaQuery("(min-width: 720px)");

  if (jobs === null) return <LoadingSpinner />;
  return (
    <Table
      rowKey="id"
      dataSource={jobs}
      pagination={pagination}
      size="middle"
      onRow={(job, _idx) => ({ onClick: navigateTo(history, `/jobs/${job.id}`) })}
      expandedRowRender={isDesktop ? job => <InlineJobRow jobId={job.id} /> : undefined}
      scroll={{ x: !isDesktop }}
    >
      {isDesktop && <Table.Column key="id" title="Job ID" dataIndex="id" render={idFormat} />}
      <Table.Column key="time" title="Time" dataIndex="created_at" render={timeFormat} />
      <Table.Column
        key="definition.title"
        title="Definition"
        dataIndex="definition.title"
        render={definitionTitleFormat}
      />
      <Table.Column
        key="metric_name"
        dataIndex="definition"
        title="Metric"
        render={definition => metricName(definition)}
      />
      <Table.Column
        key="cuts"
        dataIndex="definition"
        title="Cuts"
        render={definition =>
          (definition.data_getter_arguments.cuts
            ? cutsForDisplay(definition.data_getter_arguments.cuts)
            : metaForDisplay(definition)
          ).map(([name, value]) => <DimensionPill key={name} name={name} value={value} />)
        }
      />
      <Table.Column
        key="anomalous"
        title="Anom"
        dataIndex="direction"
        render={(direction, job: Job) => {
          if (job.completed_at == null) return <></>;
          const anom = direction && (
            <Tag color={direction === "high" ? "orange" : "cyan"}>{direction}</Tag>
          );
          return anom || <Tag>No</Tag>;
        }}
      />
      <Table.Column key="confidence" title="Confidence" dataIndex="confidence" />
      <Table.Column
        key="feedback"
        title="Feedback"
        dataIndex=""
        render={(_, job: Job) => (
          <div onClick={e => e.stopPropagation()}>
            <JobFeedbackForm job={job} noDefaultOpen />
          </div>
        )}
      />
    </Table>
  );
};

export default JobsList;
