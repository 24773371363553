import React from "react";

import styled from "styled-components/macro";
import { GoogleLogout } from "react-google-login";
import { Layout, Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import { exportUrl } from "../lib/api";
import { GOOGLE_OAUTH_CLIENT_ID } from "../lib/config";
import { useMediaQuery } from "beautiful-react-hooks";

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 22px;
  line-height: 64px;
  color: #fff;
  font-weight: normal;
`;

const Logo = styled.img`
  position: relative;
  top: -4px;
  display: inline-block;
  margin-right: 15px;
`;

const SiteHeader: React.FC<{ onLogout: () => void }> = ({ onLogout }) => {
  const history = useHistory();
  const isDesktop = useMediaQuery("(min-width: 650px)");
  const pathParts = history.location.pathname.split("/");
  return (
    <Layout.Header
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: isDesktop ? "0 20px" : "0 0 0 20px"
      }}
    >
      <Title>
        <Logo src="/helios.svg" alt="" width="36" height="30" />
        Helios
      </Title>
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={[pathParts[1]]}
        style={{ lineHeight: "64px" }}
      >
        <Menu.Item key="alerts">
          <Link to="/alerts">Alerts</Link>
        </Menu.Item>
        <Menu.Item key="jobs">
          <Link to="/jobs">Jobs</Link>
        </Menu.Item>
        <Menu.Item key="definitions">
          <Link to="/definitions">Definitions</Link>
        </Menu.Item>
        {isDesktop && (
          <Menu.SubMenu key="docs" title="Support">
            <Menu.Item key="job-request">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdTrjz_EKo370FSXLgtN4Ik9hozhwPtaBjcHKc4oFrnfeQJBQ/viewform?usp=sf_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Request a Job&hellip;
              </a>
            </Menu.Item>
            <Menu.Item key="export">
              <a href={exportUrl("job_perf_summary")}>Export Job Perf Summary&hellip;</a>
            </Menu.Item>
          </Menu.SubMenu>
        )}
        {isDesktop && (
          <Menu.Item key="logout">
            <GoogleLogout
              clientId={GOOGLE_OAUTH_CLIENT_ID}
              onLogoutSuccess={onLogout}
              render={renderProps => <span onClick={renderProps.onClick}>Logout</span>}
            />
          </Menu.Item>
        )}
      </Menu>
    </Layout.Header>
  );
};

export default SiteHeader;
