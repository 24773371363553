import React, { useState } from "react";
import { Job, Feedback } from "../types/api";
import { Icon } from "antd";
import styled from "styled-components";
import { findFeedback } from "../containers/JobFeedbackForm";

export type Direction = "up" | "down";

const VoteControls = styled.div`
  display: flex;
  flex-direction: column;
`;

const VoteIcon = styled.div<{ active: boolean; direction: Direction }>`
  color: ${({ direction, active }) =>
    active ? (direction === "up" ? "green" : "red") : "inherit"};
  opacity: ${({ active }) => (active ? 1 : 0.3)};
  cursor: pointer;
  font-size: 18px;
  &:hover {
    opacity: ${({ active }) => (active ? 1 : 0.7)};
  }
  & > i {
    display: block;
  }
`;

const VoteCount = styled.div<{ count: number }>`
  text-align: center;
  font-size: 12px;
  position: relative;
  margin: -6px 0;
  font-weight: ${({ count }) => (count === 0 ? "normal" : "bold")};
  color: ${({ count }) => (count === 0 ? "inherit" : count > 0 ? "green" : "red")};
`;

interface VoteButtonProps {
  direction: Direction;
  active: boolean;
  onClick: () => void;
}

const VoteButton: React.FC<VoteButtonProps> = ({ direction, active, onClick }) => (
  <VoteIcon
    active={active}
    direction={direction}
    title={direction === "up" ? "Useful" : "Not At All Useful"}
    onClick={onClick}
  >
    <Icon type={`caret-${direction}`} />
  </VoteIcon>
);

const feedbackCount = (feedbacks: Feedback[]): number =>
  feedbacks.reduce((n, f) => (f.usefulness === "very" ? n + 1 : n - 1), 0);

interface VoteWidgetProps {
  job: Job;
  onVote: (direction: Direction, job: Job) => void;
  onRemove: (feedback: Feedback) => void;
  email: null | string;
}

const VoteWidget: React.FC<VoteWidgetProps> = ({ job, onVote, onRemove, email }) => {
  const feedback = findFeedback(job.feedbacks, email);
  const defaultVoteState = feedback ? (feedback.usefulness === "very" ? "up" : "down") : null;
  const [voteState, setVoteState] = useState<Direction | null>(defaultVoteState);
  const [count, setCount] = useState<number>(feedbackCount(job.feedbacks));
  return (
    <VoteControls>
      <VoteButton
        direction="up"
        active={voteState === "up"}
        onClick={() => {
          if (voteState === "up") {
            setVoteState(null);
            setCount(count - 1);
            if (feedback) onRemove(feedback);
          } else {
            setVoteState("up");
            setCount(count + (voteState === "down" ? 2 : 1));
            onVote("up", job);
          }
        }}
      />
      <VoteCount count={count}>
        {count < 0 && "\u2212"}
        {count > 0 && "\u002B"}
        {Math.abs(count)}
      </VoteCount>
      <VoteButton
        direction="down"
        active={voteState === "down"}
        onClick={() => {
          if (voteState === "down") {
            setVoteState(null);
            setCount(count + 1);
            if (feedback) onRemove(feedback);
          } else {
            setVoteState("down");
            onVote("down", job);
            setCount(count - (voteState === "up" ? 2 : 1));
          }
        }}
      />
    </VoteControls>
  );
};

export default VoteWidget;
