import { History } from "history";

// navigates to the given path opening in a new tab if the metaKey is down
// This attempts to mimic browser link behaviour when needed on things that can't be links
// such as table rows
export const navigateTo = (history: History<any>, path: string) => (
  event: React.MouseEvent<Element, MouseEvent>
) => {
  if (event.metaKey) {
    window.open(path);
  } else {
    history.push(path);
  }
};
